<mat-card appearance="outlined" class="mat-mr">
    <mat-card-title class="titleRow">
        <person-chip [display]="prospect()" size="small" header></person-chip>
        <span>
            <span>Communities of Interest
                @if(selectedIds !== null){
                    ({{selectedIds.length}})
                }
            </span>
        </span>
        <div class="widgetIcons">
            <fa-icon class="resize" [icon]="closeIcon" (click)="closeWidget()"></fa-icon>
        </div>
    </mat-card-title>
    <mat-card-content>
        <span class="flex-row search-row">
            <search-input [(filter)]="requestData" [matTooltip]="'Search by Community Name'"></search-input>
            @if(submitting()){
                <load-spinner inline class="mx-auto"></load-spinner>
            }

            <!-- <reset-search (click)="comingSoon()"></reset-search>
            <advanced-search class="ml-auto" [sessionStorageName]="'notificationsAdvancedSearch'" [numberOfColumns]="4" [comingSoon]="true"></advanced-search> -->

            <div class="ml-auto flex-row">
                <button mat-raised-button (click)="comingSoon()" [disabled]="chosen()">
                    <fa-icon [icon]="findCommunitiesIcon"></fa-icon> Find Communities for Prospect
                </button>
            </div>
        </span>
        <div infiniteScroll [onScroll]="onScroll" [filter]="requestData()">
            <load-spinner variant="table"></load-spinner>
            <table mat-table [dataSource]="dataSource" class="mat-mr" [trackBy]="trackRecords">
                <ng-container matColumnDef="user-icon">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="with-checkbox">
                            <mat-checkbox [(ngModel)]="multiSelected.multiple" [indeterminate]="multiSelected.multiple && !multiSelected.all" (change)="changeMultiSelected(true)"></mat-checkbox>
                            <div class="multiLine">
                                <span>Community Name</span>
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let interest" (click)="select(interest)" class="no-mobile-label mobile-flexrow">
                        <div class="with-checkbox">
                            <mat-checkbox [(ngModel)]="multiSelected[interest.id]" (change)="changeMultiSelected(false)"></mat-checkbox>
                            <person-icon [display]="interest"></person-icon>
                            <span class="clipLines">{{interest.referrerCommunity.nameDisplay}}</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="contact-method">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="multiLine">
                            <span>Phone</span>
                            <span class="subText">Email</span>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let interest" class="contact-cell" (click)="select(interest)">
                        <div class="mobile-label">
                            <div class="multiLine">
                                <span>Phone</span>
                                <span class="subText">Email</span>
                            </div>
                        </div>
                        <div>
                            <div class="multiLine">
                                @if(interest.referrerCommunity.primaryPhone){
                                    <span [matTooltip]="(interest.referrerCommunity.primaryPhone.number | mask:'(000) 000-0000':{prefix:''})">{{(interest.referrerCommunity.primaryPhone.number | mask:"(000) 000-0000":{prefix:''})}}</span>
                                }@else {
                                    <span matTooltip="No Phone">No Phone</span>
                                }
                                <span class="subText" [matTooltip]="interest.referrerCommunity.primaryEmail ? interest.referrerCommunity.primaryEmail.address : ''">{{interest.referrerCommunity.primaryEmail ? interest.referrerCommunity.primaryEmail.address : 'No Email'}}</span>
                            </div>
                            <community-contact [community]="interest.referrerCommunity"></community-contact>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="product-type">
                    <th mat-header-cell *matHeaderCellDef> Product Type </th>
                    <td mat-cell *matCellDef="let interest" (click)="select(interest)">
                        <div class="mobile-label">
                            Product Type
                        </div>
                        @if(interest.referrerCommunity.productTypesDisplay){
                            <span class="smallerText">{{interest.referrerCommunity.productTypesDisplay}}</span>
                        }@else {
                            <span class="smallerText">None</span>
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="est-move-in">
                    <th mat-header-cell *matHeaderCellDef> Estimated Move In </th>
                    <td mat-cell *matCellDef="let interest" (click)="select(interest)">
                        <div class="mobile-label">
                            Estimated Move In
                        </div>
                        @if(chosen() && chosenId() == interest.guid){
                            <span>{{estMoveIn() | date : 'shortDate'}}</span>
                        }
                    </td>
                </ng-container>
                <ng-container matColumnDef="table-more">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let interest">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="deleteInterest(interest)" [disabled]="chosen()">
                                <fa-icon [icon]="addInvoiceIcon"></fa-icon>
                                <span>Delete</span>
                            </button>
                            @if(!chosen()){
                                <button mat-menu-item (click)="goToDepositPage(interest)">
                                    <fa-icon [icon]="depositeIcon"></fa-icon>
                                    <span>Document Deposit</span>
                                </button>
                            }@else if(chosenId() == interest.guid){
                                <button mat-menu-item (click)="goToDepositPage(interest)">
                                    <fa-icon [icon]="depositeIcon"></fa-icon>
                                    <span>View Deposit</span>
                                </button>
                            }
                            <button mat-menu-item (click)="rejectInterest(interest)" [disabled]="chosen()">
                                <fa-icon [icon]="editInvoiceIcon"></fa-icon>
                                <span>Reject/Unreject</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns(); sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns(); let interest;" [class.selected]="(chosen() && chosenId() == interest.guid)" [class.strike]="interest.rejected || (chosen() && chosenId() != interest.guid)"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns().length">
                        <div>
                            @if(requestData().search){
                                There are currently no communities. Use the "Find Communities" button to start
                            }@else {
                                There are no communities that match the current search.
                            }
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <floating-add-button (clickOrKey)="searchCommunity()" *ngIf="!chosen()"></floating-add-button>
    </mat-card-content>
</mat-card>