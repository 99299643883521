import { Component, Input } from '@angular/core';
import { Person } from '../../models/user.models';
import { IconDefinition, faBookmark, faClipboardCheck, faEnvelope, faHouseCircleCheck, faHouseUser, faLink, faLinkSlash, faMessage, faPeopleArrows, faPersonCircleMinus, faPersonWalking, faUserLock, faUserPen, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JourneyEvent } from './journey.widget';

abstract class JourneyItemRow {
    constructor(protected snackbar:MatSnackBar){
    }
    protected icon!: IconDefinition;
    // public event!:JourneyEvent;
    
    getStage(event:JourneyEvent){
        return event.stage ? event.stage.name : "Inquiry";
    }
    getStageOrder(event:JourneyEvent){
        return event.stage ? event.stage.order : 1;
    }
    
    protected getColor (personId:string) {
        return "colorName" + Person.colorIndex(personId);
    }

    protected getPersonName (person:Person) {
        if(person != null){
            // Zach Hansen, 05/23/24, bug 47658: this seamed like the wisest place to change, as Person.getPrettyName is widely used.
            // if person is null or the pretty name comes back as "Unknown", now attribute journey event to "Docentt System".
            let retVal = Person.getPrettyName(person);
            if ("Unknown" != retVal) {
                return retVal;
            }
        }
        return "Docentt System";
    }
  
    protected comingSoon(){
      this.snackbar.open("Coming Soon");
    }
}

@Component({
  selector: 'journey-message',
  templateUrl: './journey-message.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyMessageRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faMessage;
    }
    emailIcon = faEnvelope;
    
    getIcon(event:JourneyEvent){
        switch (event.event_type){
        case "Email Sent":
        case "Email Received":
            return this.emailIcon;
        default:
            return this.icon;
        }
    }
    
    getContent(event:JourneyEvent){
        switch (event.event_type){
        case "Email Sent":
            return "Sent an Email "
        case "Email Received":
            return "Sent an Email "
        case "Message Sent":
            return "Sent a Message "
        case "Message Received":
            return "Received a Message "
        default:
            return "Communicated "
        }
    }
    
    @Input() event!:JourneyEvent;
}

@Component({
  selector: 'journey-activity',
  templateUrl: './journey-activity.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyActivityRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faPersonWalking;
    }
    
    @Input() event!:JourneyEvent;
    
    getContent(event:JourneyEvent){
        switch (event.event_type){
        case "Activity Completed":
            return "Completed an Activity for "
        case "Activity Auto Closed":
            return "Auto Closed an Activity for "
        case "Activity Deleted":
            return "Deleted an Activity for "
        case "Activity Note Added":
            return "Added a Note to an Activity for "
        case "Activity Call Action Completed":
            return "Completed a Call Action on an Activity for "
        case "Tour Survey Completed":
            return "Completed a Tour Survey on an Activity for "
        case "Activity Moved":
            return "Moved an Activity to the Next Stage for "
        case "Activity Scheduled":
            return "Scheduled an Activity with "
        case "Activity Logged":
            return "Logged an Activity with "
        case "Activity Updated":
        default:
            return "Updated an Activity for "
        }
    }
}

@Component({
    selector: 'journey-assessments',
    templateUrl: './journey-assessments.row.html',
    styleUrls: ['./journey.widget.scss']
  })
  export class JourneyAssessmentsRow extends JourneyItemRow {
      constructor(protected override snackbar:MatSnackBar){
          super(snackbar);
          this.icon = faPersonWalking;
      }
      
      @Input() event!:JourneyEvent;
      
      getContent(event:JourneyEvent){
          switch (event.event_type){
          case "Assessment Created":
            return "Created an Assessment for "
          case "Assessment Deleted":
            return "Deleted an Assessment for "
          case "Assessment Signed":
            return "Signed an Assessment for "
          case "Assessment Updated":
          default:
              return "Updated an Assessment for "
          }
      }
  }

@Component({
    selector: 'journey-person-note',
    templateUrl: './journey-person-note.row.html',
    styleUrls: ['./journey.widget.scss']
  })
  export class JourneyPersonNoteRow extends JourneyItemRow {
      constructor(protected override snackbar:MatSnackBar){
          super(snackbar);
          this.icon = faPersonWalking;
      }
      
      @Input() event!:JourneyEvent;
      
      getContent(event:JourneyEvent){
          switch (event.event_type){
          case "Person Note Added":
              return "Added a Note for "
          default:
              return "Added a Note for "
          }
      }
  }

@Component({
  selector: 'journey-todo',
  templateUrl: './journey-todo.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyTodoRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faClipboardCheck;
    }
    
    @Input() event!:JourneyEvent;
    
    getContent(event:JourneyEvent){
        switch (event.event_type){
        case "Objective Completed":
            return "Completed an Objective on "
        case "Objective Deleted":
            return "Deleted an Objective on "
        case "Objective Created":
            return "Created an Objective on "
        case "Objective Updated":
        default:
            return "Updated an Objective on "
        }
    }
}

@Component({
  selector: 'journey-unit-reserved',
  templateUrl: './journey-unit-reserved.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyUnitReservedRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faClipboardCheck;
    }
    
    @Input() event!:JourneyEvent;
    
    getContent(event:JourneyEvent){
        switch (event.event_type){
        case "Unit Unreserved":
            return "Removed reservation on unit for "
        case "Unit Reserved":
        default:
            return "Reserved a unit for "
        }
    }
}

@Component({
  selector: 'journey-preadmit',
  templateUrl: './journey-preadmit.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyProspectPreadmitRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faHouseUser;
    }
    
    @Input() event!:JourneyEvent;
}

@Component({
    selector: 'journey-not-admitted',
    templateUrl: './journey-not-admitted.row.html',
    styleUrls: ['./journey.widget.scss']
  })
  export class JourneyProspectNotAdmittedRow extends JourneyItemRow {
      constructor(protected override snackbar:MatSnackBar){
          super(snackbar);
          this.icon = faHouseUser;
      }
      
      @Input() event!:JourneyEvent;
  }

@Component({
  selector: 'journey-edit-prospect',
  templateUrl: './journey-edit-prospect.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyProspectEditRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faUserPen;
    }
    
    @Input() event!:JourneyEvent;
}

@Component({
    selector: 'journey-community-of-interest',
    templateUrl: './journey-community-of-interest.row.html',
    styleUrls: ['./journey.widget.scss']
})
export class JourneyCommunityOfInterestRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faHouseCircleCheck;
    }
    
    @Input() event!:JourneyEvent;
}

@Component({
    selector: 'journey-placement-history',
    templateUrl: './journey-placement-history.row.html',
    styleUrls: ['./journey.widget.scss']
})
export class JourneyPlacementHistoryRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faPeopleArrows;
    }
    
    @Input() event!:JourneyEvent;
    
    getDescription(event:JourneyEvent){
        return event.description?.replace("Placed in ", "");
    }
}

@Component({
  selector: 'journey-link-prospect',
  templateUrl: './journey-link-prospect.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyProspectLinkRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faLink;
    }
    
    linked:Boolean = true;
    
    @Input() event!:JourneyEvent;
}
@Component({
  selector: 'journey-unlink-prospect',
  templateUrl: './journey-link-prospect.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyProspectUnlinkRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faLinkSlash;
    }

    linked:Boolean = true;
    
    @Input() event!:JourneyEvent;
}

@Component({
  selector: 'journey-new-prospect',
  templateUrl: './journey-new-prospect.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyProspectNewRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faUserPlus;
    }
    
    @Input() event!:JourneyEvent;
}

@Component({
  selector: 'journey-close-prospect',
  templateUrl: './journey-close-prospect.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyProspectCloseRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faPersonCircleMinus;
    }
    
    @Input() event!:JourneyEvent;
}

@Component({
  selector: 'journey-api-sync',
  templateUrl: './journey-api-sync-resident.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyApiSyncResidentRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faUserLock;
    }
    
    @Input() event!:JourneyEvent;
}

@Component({
    selector: 'journey-crm-import',
    templateUrl: './journey-crm-import.row.html',
    styleUrls: ['./journey.widget.scss']
  })
  export class JourneyCrmImportRow extends JourneyItemRow {
      constructor(protected override snackbar:MatSnackBar){
          super(snackbar);
          this.icon = faPersonCircleMinus;
      }
      
      @Input() event!:JourneyEvent;
  }
 
@Component({
    selector: 'journey-status-change',
    templateUrl: './journey-status-change.row.html',
    styleUrls: ['./journey.widget.scss']
  })
  export class JourneyStatusChangeRow extends JourneyItemRow {
      constructor(protected override snackbar:MatSnackBar){
          super(snackbar);
          this.icon = faPersonCircleMinus;
      }
      
      @Input() event!:JourneyEvent;
  }

@Component({
    selector: 'journey-set-on-alert',
    templateUrl: './journey-set-on-alert.row.html',
    styleUrls: ['./journey.widget.scss']
  })
  export class JourneySetOnAlertRow extends JourneyItemRow {
      constructor(protected override snackbar:MatSnackBar){
          super(snackbar);
          this.icon = faPersonCircleMinus;
      }
      
      @Input() event!:JourneyEvent;
}

@Component({
    selector: 'journey-alert-discontinued',
    templateUrl: './journey-alert-discontinued.row.html',
    styleUrls: ['./journey.widget.scss']
  })
  export class JourneyAlertDiscontinuedRow extends JourneyItemRow {
      constructor(protected override snackbar:MatSnackBar){
          super(snackbar);
          this.icon = faPersonCircleMinus;
      }
      
      @Input() event!:JourneyEvent;
}

@Component({
    selector: 'journey-prospect-assignment',
    templateUrl: './journey-prospect-assignment.row.html',
    styleUrls: ['./journey.widget.scss']
  })
  export class JournyProspectAssignmentRow extends JourneyItemRow {
      constructor(protected override snackbar:MatSnackBar){
          super(snackbar);
          this.icon = faPersonCircleMinus;
      }
      
      @Input() event!:JourneyEvent;
}

@Component({
  selector: 'journey-generic',
  templateUrl: './journey-generic.row.html',
  styleUrls: ['./journey.widget.scss']
})
export class JourneyGenericRow extends JourneyItemRow {
    constructor(protected override snackbar:MatSnackBar){
        super(snackbar);
        this.icon = faBookmark;
    }
    
    @Input() event!:JourneyEvent;
}