import { Address, Email, IContactable, INameDisplay, Phone, PhotoLink } from "./base.models"
import { CareGiver, CareNeeds, DecisionCriteria, ExpectedStay, Familiarity, 
    Gender, InquiryType, InterestsAndHobbies, LivingArrangements, MaritalStatus, 
    MoveInTime, ProductType, ProspectCommunityFitnessQualifier, Readiness, 
    ReasonsAndTriggers, ReferralSource, Relation, VeteranStatus, OrganizationType, 
    SalesActivity, WorkflowStage, StageTodo, 
    OtherContactType,
    MarketingFileType,
    ActivityCompletedReason,
    Diagnosis,
    FundingSource,
    LookupTableModel,
    LookupTableUtil} from "./lookuptable.models"

export interface PersonModel extends IContactable, IPageable {
    personId:string|null
    firstname:string
    lastname:string
    middlename:string
    type:UserRole
}

export interface IPageable {
    pageNumber?:number
}

export interface ScheduledActivity extends IPageable{
    guid:string,
    startTime:Date,
    endTime:Date,
    completeTime:Date|null,
    allDay:boolean,
    person:Person,
    people:Person[],
    salesCounselor:Person,
    notes:string,
    salesActivity:SalesActivity,
    stage?:WorkflowStage;
    status:"Logged"|"Scheduled"|"Completed",
    inbound:boolean;
    deleted:boolean;
    timezone:string;
    dateOnlyDisplay:string;
    completeTimeDisplay:string;
    timeOnlyStartDisplay:string;
    timeOnlyEndDisplay:string;
    activityOverdue:boolean;
    results:ActivityCompletedReason|null,
    actionCompleted:boolean;
    autoClosed:boolean;
    contactList:any[]|null;
    type:"Prospect"|"Referrer"|"Staff";
    savedNote?: {datestamp:Date; note: string}
    personType:"Prospect"|"Referrer"|"Staff",
    referrerCommunity:ReferrerCommunity|null;
}

export interface CloseObject extends IPageable{
    close_id:string,
    personId:string,
    close_reason_id:string,
    close_reason:string,
    close_date:Date,
    close_comments:string,
    companyId:string;
}

export interface TodoTask{
    guid:string,
    completionTime:Date,
    prospect:Person,
    salesCounselor:Person,
    stageTodo:StageTodo,
    stage:WorkflowStage;
    status:"Objective"|"Completed",
    deleted:boolean;
}


export class SimplePerson implements INameDisplay {
    constructor(id:string, first:string, middle:string, last:string){
        this.personId = id;
        this.firstname = first;
        this.middlename = middle;
        this.lastname = last;
    }
    
    personId: string
    firstname: string
    lastname: string
    middlename: string
    email?:string
    
    get id() {
        return this.personId;
    }
    
    get nameDisplay(): string {
        return Person.getPrettyName(this);
    }
    
    get nameAbbreviation(): string {
        let first = "", second = "";
        if(this.firstname && this.firstname.length > 0){
            first = this.firstname[0]
        }
        if(this.lastname && this.lastname.length > 0){
            second = this.lastname[0]
        }
        let initials = first + second;
        if(initials.length <= 0){
            initials = "?";
        }
        return initials;
    }
}

export class Person implements PersonModel {
    static fromJson(obj?:any|null){
        if(obj == null){
            return null;
        }
        let user = new Person();
        user.personId = obj.personId;
        user.firstname = obj.firstname;
        user.lastname = obj.lastname;
        user.middlename = obj.middlename;
        
        user.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
        user.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
        user.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
            p.number = p.number ? p.number.replaceAll(".", "") : "";
            return p;
        }) : [];
        
        if(obj.pageNumber != null){
            user.pageNumber = obj.pageNumber;
        }

        if(obj.referrerCommunity != null && obj.referrerCommunity != undefined && obj.referrerCommunity.referrerCommunityId != null && obj.referrerCommunity.referrerCommunityId != undefined && obj.referrerCommunity.referrerCommunityId != "") {
            user.communityId = obj.referrerCommunity.referrerCommunityId;
        }
        
        return user;
    }
    
    constructor(){
        this.personId = "";
        this.firstname = "";
        this.middlename = "";
        this.lastname = "";
        this.mailAddresses = [];
        this.emailAddresses = [];
        this.phoneNumbers = [];
    }
    personId!: string
    firstname!: string
    lastname!: string
    middlename: string
    type!: UserRole
    mailAddresses:Address[]
    emailAddresses:Email[]
    phoneNumbers:Phone[]
    pageNumber?: number;
    communityId?: string;
    
    public prettyName(): string {
        return Person.getPrettyName(this);
    } 

    get id() {
        return this.personId;
    }
    
    get nameDisplay(): string {
        return this.prettyName();
    }
    
    get nameAbbreviation(): string {
        let first = "", second = "";
        if(this.firstname && this.firstname.length > 0){
            first = this.firstname[0]
        }
        if(this.lastname && this.lastname.length > 0){
            second = this.lastname[0]
        }
        let initials = first + second;
        if(initials.length <= 0){
            initials = "?";
        }
        return initials;
    }
    
    //See $person-palette in styles.scss to get this number
    private static colorClassCount = 30;
    private static readonly valmap:{[key:string]:number} = { 
        '0':1, '1':2, '2':3, '3':4, '4':5, '5':6, '6':7, '7':8, '8':9, '9':10, 
        '-':11, 'a':12, 'b':13, 'c':14, 'd':15, 'e':16, 'f':17, 'g':18, 'h':19,
        'i':20, 'j':21, 'k':22, 'l':23, 'm':24, 'n':25, 'o':26, 'p':27, 'q':28,
        'r':29, 's':30, 't':31, 'u':32, 'v':33, 'w':34, 'x':35, 'y':36, 'z':37,
    }
    private static isLowerCase = function(str:string){
        return str === str.toLowerCase() &&
           str !== str.toUpperCase();
    }
    static colorIndex (input:string) {
        if(!input || input == ""){
          return "none";  
        }
        //Turn guid into some number between 0 and colorClassCount
        let classInt = input
            .split("")
            .map(c=>{
                let value = Person.valmap[c.toLowerCase()];
                if(!value){
                    value = 0;
                }
                if(!Person.isLowerCase(c)){
                    value += 26;
                }
                return value;
            })
            .filter(n=>Number.isInteger(n) && Number.isFinite(n))
            .reduce((a,b) => a+b, 0);
        return ""+(classInt % this.colorClassCount);
    }
    
    static getRoleDisplay(person:{type:UserRole}){
        switch(person.type){
        case UserRole.Admin:
            return "Admin";
        case UserRole.Referrer:
            return "Referrer";
        case UserRole.Influencer:
            return "Influencer";
        case UserRole.OtherContact:
            return "Other Contact";
        case UserRole.Prospect:
            return "Prospect";
        case UserRole.User:
            return "User";
        case UserRole.Staff:
            return "Staff";
        default:
            return "None";
        }
    }
    
    static getPrettyName(person:{firstname:string, middlename:string, lastname:string}){
        var name:string = "";
    
        let noFirst = !person.firstname || person.firstname?.trim().length <= 0;
        let noMiddle = !person.middlename || person.middlename?.trim().length <= 0;
        let noLast = !person.lastname || person.lastname?.trim().length <= 0;
    
        let first:string = noFirst ? "" : person.firstname.trim();
        let middle:string = noMiddle ? "" : person.middlename.trim();
        let last:string = noLast ? "" : person.lastname.trim();
    
        if(noFirst && noMiddle && noLast){
            name = "Unknown";
        }else{
            if(noFirst){
                first = "Unknown";
            }
            if(noLast){
                last = "Unknown";
            }
        
            name = first;
            if(!noMiddle){
                name += " " + middle;
            }
            name += " " + last;
        }
        return name;
    }
    
    addType(role: UserRole){
        //Sets type to the union of current type and new role
        this.type = this.type | role;
    }
    removeType(role: UserRole){
        //Sets type to the current type without the provided role
        this.type = this.type & ~role;
    }
    hasType(role: UserRole){
        //Checks if the intersection of user type and provided type is provided type
        return (this.type & role) === role;
    }
}

export class Prospect extends Person{
    public static override fromJson(obj?:any|null){
        if(obj == null){
            return null;
        }
        let prospect = new Prospect();
        if(obj.creationDate){
            prospect.creationDate = new Date(obj.creationDate);
        }
        prospect.personId = obj.personId;
        prospect.firstname = obj.firstname;
        prospect.lastname = obj.lastname;
        prospect.middlename = obj.middlename;
        prospect.pending = obj.pending;
        prospect.companyId = obj.companyId;
        prospect.assignedUserId = obj.assignedUserId;
        prospect.assignedUserName = obj.assignedUserName;
        prospect.assignedUser = null;
        if(prospect.assignedUserId && prospect.assignedUserName){
            let first = "", last = "", middle = "";
            let split = obj.assignedUserName.split(" ");
            if(split[0]){
                first = split[0];
            }
            if(split[2]){
                last = split[2];
                middle = split[1]
            }else{
                last = split[1];
            }
            prospect.assignedUser = new SimplePerson(obj.assignedUserId, first, middle, last);
        }
        prospect.externalId = obj.externalId;
        prospect.nextActivity = obj.nextActivity ? obj.nextActivity as ScheduledActivity : null;
        prospect.closeObject = obj.closeObject ? obj.closeObject as CloseObject : null;
        // prospect.type = obj.person.role;
        prospect.biography = obj.biography;
        if(obj.birthDate != null){
            prospect.birthDate = new Date(obj.birthDate);
        }
        if(obj.inquiryDate != null){
            prospect.inquiryDate = new Date(obj.inquiryDate);
        }
        if(obj.firstContact != null){
            prospect.firstContact = new Date(obj.firstContact);
        }
        if(obj.lastContact != null){
            prospect.lastContact = new Date(obj.lastContact);
        }
        if(obj.age){
            prospect.age = obj.age;
        }
        prospect.inquiryNotes = obj.inquiryNotes;
        prospect.salutation = obj.salutation;
    
        prospect.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
        prospect.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
        prospect.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
            p.number = p.number ? p.number.replaceAll(".", "") : "";
            return p;
        }) : [];

        prospect.score = obj.score;
        prospect.infoScore = obj.infoScore;
        prospect.pipeline = obj.pipeline;
        prospect.currentStageIndex = obj.currentStageIndex;

        prospect.stage = obj.stage;
        prospect.productType = obj.productType;
        prospect.expectedStay = obj.expectedStay;
        prospect.gender = obj.gender;
        prospect.maritalStatus = obj.maritalStatus;
        prospect.veteranStatus = obj.veteranStatus;
        prospect.readiness = obj.readiness;
        prospect.familiarity = obj.familiarity;
        prospect.livingArrangements = obj.livingArrangements;
        prospect.currentCareGiver = obj.currentCareGiver;
        prospect.expectedMoveInTime = obj.expectedMoveInTime;
        prospect.inquiryType = obj.inquiryType;
        prospect.hairColor = obj.hairColor;
        prospect.eyeColor = obj.eyeColor;
        prospect.distinguishingMarks = obj.distinguishingMarks;
        prospect.language = obj.language;
        prospect.race = obj.race;
        prospect.raceOther = obj.raceOther;

        prospect.primary_physician_name = obj.primary_physician_name;
        prospect.mdpoa = obj.mdpoa;
        prospect.allergies = obj.allergies ? obj.allergies : [];
        prospect.diagnoses = obj.diagnoses ? obj.diagnoses : [];
        prospect.medications = obj.medications ? obj.medications : [];
        prospect.financial_situation = obj.financial_situation;
        prospect.funding_sources = obj.funding_sources ? obj.funding_sources : [];
        prospect.insurance_information = obj.insurance_information;

        prospect.ssn = obj.ssn;
        prospect.pets = obj.pets;
        prospect.pet_information = obj.pet_information;
        prospect.smoker = obj.smoker;
        prospect.drives = obj.drives;

        prospect.communityPreferences = obj.communityPreferences ? obj.communityPreferences : [];
       
        if(obj.preadmitInProgress == null || obj.preadmitInProgress == undefined) {
            prospect.preadmitInProgress = false;
        } else {
            prospect.preadmitInProgress = obj.preadmitInProgress;
        }

        if(obj.editInProgress == null || obj.editInProgress == undefined) {
            prospect.editInProgress = false;
        } else {
            prospect.editInProgress = obj.editInProgress;
        }

        if(obj.stagesCompleted == null || obj.stagesCompleted == undefined) {
            prospect.stagesCompleted = false;
        } else {
            prospect.stagesCompleted = obj.stagesCompleted;
        }

        prospect.religion = obj.religion;
        prospect.religionOther = obj.religionOther;
        prospect.typicalDay = obj.typicalDay;
        prospect.priorPlacements = obj.priorPlacements;
        prospect.recentLosses = obj.recentLosses;
        prospect.isClosed = !!obj.isClosed;

        prospect.linkedRelation = obj.linkedRelation;
        prospect.linkedProspectId = obj.linkedProspectId;

        prospect.referralSource = obj.referralSource ? obj.referralSource : [];
        prospect.decisionCriteria = obj.decisionCriteria ? obj.decisionCriteria : [];
        prospect.careNeeds = obj.careNeeds ? obj.careNeeds : [];
        prospect.interestsAndHobbies = obj.interestsAndHobbies ? obj.interestsAndHobbies : [];
        prospect.reasonsAndTriggers = obj.reasonsAndTriggers ? obj.reasonsAndTriggers : [];
        prospect.currentCaregivers = obj.currentCaregivers ? obj.currentCaregivers : [];
        
        prospect.contacts = obj.contacts ? obj.contacts : [];
        
        if(obj.communitiesOfInterest?.length > 0){
            prospect.communitiesOfInterest = obj.communitiesOfInterest.map((c: any)=>Community.fromJson(c));
        }else if(obj.referrerCommunitiesOfInterest?.length > 0) {
            prospect.communitiesOfInterest = obj.referrerCommunitiesOfInterest.map((c: any)=>ReferrerCommunity.fromJson(c));
        }else{
            prospect.communitiesOfInterest = [];
        }
        
        prospect.communityFitnessQualifiers = {};
        if(obj.communityFitnessQualifiers){
            for(let qual of obj.communityFitnessQualifiers as ProspectCommunityFitnessQualifier[]){
                prospect.communityFitnessQualifiers[qual.lookup_guid] = qual.value;
            }
        }
        
        if(obj.contacts){
            prospect.contacts = [];
            prospect.contacts.push(...obj.contacts
                .map((c: any)=>{
                    if(c.contact_type == 'influencer'){
                        return Influencer.fromJson(c);
                    }else if(c.contact_type == 'referrer'){
                        return Referrer.fromJson(c);
                    }else if(c.contact_type == 'other'){
                        return OtherContact.fromJson(c);
                    }else{
                        console.error("Unable to determin type of object from server:\n" + JSON.stringify(c));
                        return null;
                    }
                })
                .filter((c: any|null) => c != null));
        }
        
        if(obj.pageNumber != null){
            prospect.pageNumber = obj.pageNumber;
        }
    
        return prospect;
    }
    
    constructor (){
        super();
        this.type = UserRole.Prospect;
        this.creationDate = new Date();
        this.score = 0;
        this.infoScore = 0;
        this.salutation = "";
        this.inquiryNotes = "";
        this.biography = "";
        this.decisionCriteria = [];
        this.referralSource = [];
        this.careNeeds = [];
        this.interestsAndHobbies = [];
        this.reasonsAndTriggers = [];
        this.communitiesOfInterest = [];
        this.contacts = [];
        this.age = null;
        this.hairColor = "";
        this.eyeColor = "";
        this.distinguishingMarks = "";
        this.language = "";
        this.race = "";
        this.raceOther = "";
        this.preadmitInProgress = false;
        this.editInProgress = false;
        this.stagesCompleted = false;
        this.religion = "";
        this.religionOther = "";
        this.typicalDay = "";
        this.priorPlacements = "";
        this.recentLosses = "";
        this.pending = true;
        this.assignedUser = null;
        this.assignedUserId = null;
        this.assignedUserName = null;
        this.externalId = null;
        this.nextActivity = null;
        this.closeObject = null;
        this.isClosed = false;
        
        this.linkedRelation = null;
        this.linkedProspectId = null;

        this.primary_physician_name = "";
        this.mdpoa = "";
        this.allergies = [];
        this.diagnoses = [];
        this.medications = [];
        this.financial_situation = "";
        this.funding_sources = [];
        this.insurance_information = "";

        this.ssn = "";
        this.pets = "";
        this.pet_information = "";
        this.smoker = "";
        this.drives = "";
        this.currentCaregivers = [];
        this.communityPreferences = [];
    }
    birthDate!:Date;
    age:number|null;
    companyId!:string;
    inquiryDate!:Date;
    creationDate:Date;
    lastContact!:Date;
    firstContact!:Date;
    inquiryNotes:String;
    salutation:string;
    biography:string;
    pending:boolean;
    
    stage!:WorkflowStage;
    productType!:ProductType;
    expectedStay!:ExpectedStay;
    gender!:Gender;
    maritalStatus!:MaritalStatus;
    veteranStatus!:VeteranStatus;
    readiness!:Readiness;
    familiarity!:Familiarity;
    livingArrangements!:LivingArrangements;
    currentCareGiver!:CareGiver;
    expectedMoveInTime!:MoveInTime;
    inquiryType!:InquiryType;
    referralSource:ReferralSource[];
    decisionCriteria:DecisionCriteria[];
    careNeeds:CareNeeds[];
    interestsAndHobbies:InterestsAndHobbies[];
    reasonsAndTriggers:ReasonsAndTriggers[];
    communityFitnessQualifiers:any;
    hairColor:string;
    eyeColor:string;
    distinguishingMarks:string;
    language:string;
    race:string;
    raceOther:string;
    preadmitInProgress:boolean;
    editInProgress:boolean;
    stagesCompleted:boolean
    religion:string;
    religionOther:string;
    typicalDay:string;
    priorPlacements:string;
    recentLosses:string;
    assignedUserId:string|null;
    assignedUserName:string|null;
    assignedUser:SimplePerson|null;
    externalId:string|null;
    isClosed:boolean;
    
    linkedProspectId:string|null;
    linkedRelation:Relation|null;
    
    contacts:Contact[];
    
    communitiesOfInterest:AbstractCommunity[];
    
    score: number;
    infoScore: number;
    pipeline!: PipelineModel;
    currentStageIndex!: number;
    nextActivity!:ScheduledActivity|null;
    closeObject!:CloseObject|null;

    primary_physician_name: string;
    mdpoa: string;
    allergies:Allergy[];
    diagnoses:Diagnosis[];
    medications:Medication[];
    financial_situation: string;
    funding_sources:FundingSource[];
    insurance_information: string;
    ssn: string;
    pets: string;
    pet_information: string;
    smoker: string;
    drives: string;
    currentCaregivers: CareGiver[];
    communityPreferences: ReferenceAttributeProspect[] = [];
    
    currentStage(): PipelineStageModel {
        return this.pipeline.stages[this.currentStageIndex];
    }
    
    public static inquiry_notes_length = 1024;
}

export interface Allergy {
    allergyId?:string;
    personId?:string;
    value?:string;
    deleted?:boolean;
}

export interface Medication {
    medicationId?:string;
    personId?:string;
    value?:string;
    deleted?:boolean;
}

export class ReferenceAttributePreferences {
    public static fromJson(obj:any|undefined){
        if(obj){
            return new ReferenceAttributePreferences(obj);
        }else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj) {
            this.attributeId = obj.attributeId;
            this.label = obj.label;
            this.communityDisplay = obj.communityDisplay;
            this.prospectDisplay = obj.prospectDisplay;
            this.type = obj.type;
            this.lookupTable = obj.lookupTable;
            this.communityId = obj.communityId;
            this.companyId = obj.company;
            this.lookupOrder = obj.lookupOrder;
            this.linkedTo = obj.linkedTo;
            this.addMultiple = obj.addMultiple;
            this.lookupTableList = obj.lookupTableList ? obj.lookupTableList.sort(LookupTableUtil.sort) : [];
            
            this.linkedQuestions = obj.linkedQuestions ? obj.linkedQuestions : [];
            this.multipleItemIndex = obj.multipleItemIndex ? obj.multipleItemIndex : 1;
            this.extraAdded = obj.extraAdded ? obj.extraAdded : false;
            this.commLabel = obj.commLabel;
            this.commType = obj.commType;
            this.commAddMultiple = obj.commAddMultiple;
            this.obj = obj.obj;
        } else{
            this.attributeId = "";
            this.label = "";
            this.communityDisplay = false;
            this.prospectDisplay = false;
            this.type = "";
            this.lookupTable = "";
            this.communityId = "";
            this.companyId = "";
            this.lookupOrder = 0;
            this.linkedTo = "";
            this.addMultiple = false;
            this.lookupTableList = [];
            this.linkedQuestions = [];
            this.multipleItemIndex = 1;
            this.extraAdded = false;
            this.commLabel = "";
            this.commType = "";
            this.commAddMultiple = false;
            this.obj = "";
        }
    }

    attributeId:string;
    label:string;
    communityDisplay:boolean;
    prospectDisplay:boolean;
    type:string;
    lookupTable:string;
    communityId:string;
    companyId:string;
    lookupOrder:number;
    linkedTo:string;
    addMultiple:boolean;
    lookupTableList:LookupTableModel[] = [];
    linkedQuestions:ReferenceAttributePreferences[] = [];
    multipleItemIndex:number;
    extraAdded:boolean;
    commType:string;
    commLabel:string;
    commAddMultiple:boolean;
    obj:string;
}

export class ReferenceAttributeProspect {
    public static fromJson(obj:any|undefined){
        if(obj){
            return new ReferenceAttributeProspect(obj);
        }else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj) {
            this.guid = obj.guid;
            this.personId = obj.personId;
            this.attributeId = obj.attributeId;
            this.value = obj.value;
            this.obj = obj.obj;
            this.communityId = obj.communityId;
            this.companyId = obj.companyId;
            this.linkedOrder = obj.linkedOrder;
        } else{
            this.guid = "";
            this.personId = "";
            this.attributeId = "";
            this.value = "";
            this.obj = null;
            this.communityId = "";
            this.companyId = "";
            this.linkedOrder = null;
        }
    }

    guid:string;
    personId:string;
    attributeId:string;
    value:string|null;
    obj:string|null;
    communityId:string|null;
    companyId:string|null;
    linkedOrder:number|null;
}

export class ReferenceAttributeCommunity {
    public static fromJson(obj:any|undefined){
        if(obj){
            return new ReferenceAttributeCommunity(obj);
        }else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj) {
            this.guid = obj.guid;
            this.referrerCommunityId = obj.referrerCommunityId;
            this.attributeId = obj.attributeId;
            this.value = obj.value;
            this.obj = obj.obj;
            this.communityId = obj.communityId;
            this.companyId = obj.companyId;
            this.linkedOrder = obj.linkedOrder;
        } else{
            this.guid = "";
            this.referrerCommunityId = "";
            this.attributeId = "";
            this.value = "";
            this.obj = null;
            this.communityId = "";
            this.companyId = "";
            this.linkedOrder = null;
        }
    }

    guid:string;
    referrerCommunityId:string;
    attributeId:string;
    value:string|null;
    obj:string|null;
    communityId:string|null;
    companyId:string|null;
    linkedOrder:number|null;
}

export abstract class Contact extends Person{
    constructor (){
        super();
        this.salutation = "";
        this.notes = "";
        this.preferred_contact_method = "";
        this.do_not_call = false;
        this.do_not_text = false;
        this.do_not_email = false;
        this.do_not_postal_mail = false;
    }
    
    salutation:string;
    preferred_contact_method:string;
    do_not_call:boolean;
    do_not_text:boolean;
    do_not_email:boolean;
    do_not_postal_mail:boolean;
    notes:String;
    // abstract isInfluencer():boolean;
    // abstract isReferrer():boolean;
}
export interface InfluencerRelationship{
    [key: string]: Relation[]
}
export class Influencer extends Contact{

    public static override fromJson(obj?:any|null){
        let influencer = new Influencer();
        influencer.personId = obj.personId;
        influencer.firstname = obj.firstname;
        influencer.lastname = obj.lastname;
        influencer.middlename = obj.middlename;
        // prospect.type = obj.person.role;
        if(obj.birth_date != null){
            influencer.birth_date = new Date(obj.birth_date);
        }
        influencer.notes = obj.notes;
        influencer.salutation = obj.salutation;
        influencer.preferred_contact_method = obj.preferred_contact_method;
        influencer.do_not_call = obj.do_not_call ? obj.do_not_call : false;
        influencer.do_not_text = obj.do_not_text ? obj.do_not_text : false;
        influencer.do_not_email = obj.do_not_email ? obj.do_not_email : false;
        influencer.do_not_postal_mail = obj.do_not_postal_mail ? obj.do_not_postal_mail : false;
        
        if(obj.relationships != null){
            for(let rel of obj.relationships){
                if(!influencer.relationships[rel.prospectId]){
                    influencer.relationships[rel.prospectId] = [];
                }
                influencer.relationships[rel.prospectId].push(rel.relation as Relation);
            }
        }
    
        influencer.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
        influencer.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
        influencer.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
            p.number = p.number ? p.number.replaceAll(".", "") : "";
            return p;
        }) : [];
    
        influencer.gender = obj.gender;
        
        if(obj.pageNumber != null){
            influencer.pageNumber = obj.pageNumber;
        }
    
        return influencer;
    }
    
    constructor (){
        super();
        this.type = UserRole.Influencer;
        
        this.relationships = {} as InfluencerRelationship;
    }
    
    relationships:InfluencerRelationship;
    birth_date!:Date;
    gender!:Gender;
    // isInfluencer() {
    //     return true;
    // };
    // isReferrer() {
    //     return false;
    // };
}

export class Referrer extends Contact{
    public static override fromJson(obj?:any|null){
        let referrer = new Referrer();
        if(obj.creationDate){
            referrer.creationDate = new Date(obj.creationDate);
        }
        referrer.personId = obj.personId;
        referrer.firstname = obj.firstname;
        referrer.lastname = obj.lastname;
        referrer.middlename = obj.middlename;
        referrer.position_title = obj.position_title;
        referrer.notes = obj.notes;
        referrer.salutation = obj.salutation;
        referrer.preferred_contact_method = obj.preferred_contact_method;
        referrer.do_not_call = obj.do_not_call ? obj.do_not_call : false;
        referrer.do_not_text = obj.do_not_text ? obj.do_not_text : false;
        referrer.do_not_email = obj.do_not_email ? obj.do_not_email : false;
        referrer.do_not_postal_mail = obj.do_not_postal_mail ? obj.do_not_postal_mail : false;
        referrer.grade = obj.grade ? obj.grade : "";
    
        referrer.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
        referrer.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
        referrer.nextActivity = obj.nextActivity ? obj.nextActivity as ScheduledActivity : null;
        referrer.completedActivity = obj.completedActivity ? obj.completedActivity as ScheduledActivity : null;
        referrer.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
            p.number = p.number ? p.number.replaceAll(".", "") : "";
            return p;
        }) : [];

        if (obj.organization == null) {
            referrer.organization = new Organization();
        } else {
            referrer.organization = Organization.fromJson(obj.organization);  
        }

        if(obj.firstContact != null){
            referrer.firstContact = new Date(obj.firstContact);
        }
        if(obj.lastContact != null){
            referrer.lastContact = new Date(obj.lastContact);
        }
    
        referrer.deleted = obj.deleted;
        
        if(obj.pageNumber != null){
            referrer.pageNumber = obj.pageNumber;
        }
        
        referrer.primaryEmail = obj.primaryEmail;
        referrer.primaryPhone = obj.primaryPhone;
        return referrer;
    }
    constructor (){
        super();
        this.type = UserRole.Referrer;
        this.creationDate = new Date();
        this.position_title = "";
        this.deleted = false;
        this.nextActivity = null;
        this.grade = "";
        this.completedActivity = null;
        this.primaryEmail = { allowMarketing:true, allowSalesContact:true } as Email;
    }
    position_title:string;
    organization!:Organization;
    lastContact!:Date;
    firstContact!:Date;
    nextActivity!:ScheduledActivity|null;
    completedActivity!:ScheduledActivity|null;
    grade:string;
    deleted:boolean

    primaryEmail:Email;
    primaryPhone!:Phone;
    creationDate:Date;
    // isInfluencer() {
    //     return false;
    // };
    // isReferrer() {
    //     return true;
    // };
}

export class ReferrerStaff extends Contact{
    public static override fromJson(obj:any){
        let referrer = new ReferrerStaff();
        referrer.personId = obj.personId;
        referrer.firstname = obj.firstname;
        referrer.lastname = obj.lastname;
        referrer.middlename = obj.middlename;
        referrer.position_title = obj.position_title;
        referrer.notes = obj.notes;
        referrer.salutation = obj.salutation;
        referrer.preferred_contact_method = obj.preferred_contact_method;
        referrer.do_not_call = obj.do_not_call ? obj.do_not_call : false;
        referrer.do_not_text = obj.do_not_text ? obj.do_not_text : false;
        referrer.do_not_email = obj.do_not_email ? obj.do_not_email : false;
        referrer.do_not_postal_mail = obj.do_not_postal_mail ? obj.do_not_postal_mail : false;
        

        referrer.nextActivity = obj.nextActivity ? obj.nextActivity as ScheduledActivity : null;
    
        referrer.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
        referrer.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
        referrer.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
            p.number = p.number ? p.number.replaceAll(".", "") : "";
            return p;
        }) : [];

        if (obj.referrerCommunity == null) {
            referrer.referrerCommunity = new ReferrerCommunity();
        } else {
            referrer.referrerCommunity = ReferrerCommunity.fromJson(obj.referrerCommunity);  
        }

        // Added this so the activities with the people involved field show the staff members by community correctly.
        if(obj.referrerCommunity != null && obj.referrerCommunity != undefined && obj.referrerCommunity.referrerCommunityId != null && obj.referrerCommunity.referrerCommunityId != undefined && obj.referrerCommunity.referrerCommunityId != "") {
            referrer.communityId = obj.referrerCommunity.referrerCommunityId;
        }

        if(obj.firstContact != null){
            referrer.firstContact = new Date(obj.firstContact);
        }
        if(obj.lastContact != null){
            referrer.lastContact = new Date(obj.lastContact);
        }
    
        referrer.deleted = obj.deleted;
        
        referrer.primaryEmail = obj.primaryEmail;
        return referrer;
    }
    
    constructor (){
        super();
        this.type = UserRole.Staff;
        this.position_title = "";
        this.deleted = false;
        this.nextActivity = null;
        this.primaryEmail = { allowMarketing:true, allowSalesContact:true } as Email;
    }
    position_title:string;
    referrerCommunity!:ReferrerCommunity;
    lastContact!:Date;
    firstContact!:Date;

    deleted:boolean
    
    nextActivity:ScheduledActivity|null;

    primaryEmail:Email;
    // isInfluencer() {
    //     return false;
    // };
    // isReferrer() {
    //     return true;
    // };
}
export class OtherContact extends Contact{
    public static override fromJson(obj?:any|null){
        let contact = new OtherContact();
        contact.personId = obj.personId;
        contact.firstname = obj.firstname;
        contact.lastname = obj.lastname;
        contact.middlename = obj.middlename;
        
        contact.notes = obj.notes;
        contact.salutation = obj.salutation;
        contact.preferred_contact_method = obj.preferred_contact_method;
        contact.do_not_call = obj.do_not_call ? obj.do_not_call : false;
        contact.do_not_text = obj.do_not_text ? obj.do_not_text : false;
        contact.do_not_email = obj.do_not_email ? obj.do_not_email : false;
        contact.do_not_postal_mail = obj.do_not_postal_mail ? obj.do_not_postal_mail : false;
    
        contact.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
        contact.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
        contact.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
            p.number = p.number ? p.number.replaceAll(".", "") : "";
            return p;
        }) : [];
    
        contact.deleted = obj.deleted;
        
        contact.otherType = obj.otherType as OtherContactType;
        
        if(obj.pageNumber != null){
            contact.pageNumber = obj.pageNumber;
        }
        return contact;
    }
    constructor (){
        super();
        this.type = UserRole.OtherContact;
        this.deleted = false;
        this.otherType = {} as OtherContactType;
    }

    deleted:boolean

    otherType:OtherContactType;
    // isInfluencer() {
    //     return false;
    // };
    // isReferrer() {
    //     return true;
    // };
}


export class User extends Person{
    public static override fromJson(obj?: any): User | null {
        if(obj == null){
            return null;
        }
        let user = new User();
        user.active = obj.active;
        user.locked = obj.locked;
        user.personId = obj.personId;
        user.firstname = obj.firstname;
        user.lastname = obj.lastname;
        user.middlename = obj.middlename;
        user.company = obj.company;
        user.userTitle = obj.userTitle;
        user.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
        user.email = obj.userEmail;
        user.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [{address:obj.userEmail}];
        user.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
            p.number = p.number ? p.number.replaceAll(".", "") : "";
            return p;
        }) : [];
        user.userRole = obj.userRole;
        if (user.userRole == null) {
            user.userRole = '';
        }
        user.autoAssign = obj.autoAssign;
        user.homepage = obj.homepage;
        user.userTimezone = obj.userTimezone;
        if (user.userTimezone == null) {
            user.userTimezone = '';
        }
        if(obj.lastLogin){
            user.lastLogin = new Date(obj.lastLogin);
        }

        user.activationDate = obj.activationDate;
        
        user.superadmin = obj.superadmin
        
        if(obj.pageNumber != null){
            user.pageNumber = obj.pageNumber;
        }
        
        return user;
    }
    
    constructor (){
        super();
        this.type = UserRole.User;
        this.lastLogin = null;
    }
    company!:Company;
    active!:boolean;
    locked!:boolean;
    email!:string;
    userTitle!:string;
    authorities!:string[];
    
    userRole!:string;
    autoAssign!:boolean;
    homepage!:string|null;
    userTimezone!:string;
    lastLogin:Date|null;

    activationDate!:string;

    superadmin!:boolean;
    communities:Community[] = [];
}

export class ProspectExternalInfo {
    static fromJson (obj?:any|null):ProspectExternalInfo|null {
        if(obj){
            return new ProspectExternalInfo(obj);
        }else{
            return null;
        }
    }
    
    constructor (obj:any){
        this.personId = obj.personId;
        this.status = obj.status;
        this.alertStatus = obj.alertStatus;
        this.unitId = obj.unitId;
        this.importedRecord = obj.importedRecord;
        this.locked = obj.locked;
        this.moveOutDate = obj.moveOutDate; // Zach Hansen, 05/29/24, bug 47690: want to display move out dates on Occupancy page when applicable.

    }
    
    personId:string
    status:string|null
    alertStatus:string|null
    unitId:string|null
    importedRecord:string|null
    locked:boolean|null
    moveOutDate:Date|null
}

export interface PipelineModel {
    guid:string,
    name:string,
    stages:PipelineStageModel[]
}

export interface PipelineStageModel {
    guid:string,
    name:string,
    index:number
}

export class LoggedEvent implements IPageable {
    constructor(){
        this.eventId = "";
        this.targetId = "";
        this.type = "";
        this.subtype = "";
        this.description = "";

        this.user = {} as User;
        this.organization = {} as Organization;
        this.company = {} as Company;
        this.deleted = false;
    }
    eventId:string
    user:User
    targetId:string
    type:string
    subtype:string
    description:string
    datestamp!:Date
    organization:Organization
    company:Company
    deleted:boolean
    pageNumber?: number;
}

export class Notification implements IPageable{
    constructor(){
        this.eventId = "";
        this.targetId = "";
        this.type = "";
        this.description = "";
        this.user = {} as User;
        this.company = {} as Company;
    }
    eventId:string
    targetId:string
    type:string
    description:string
    user:User
    company:Company
    datestamp!:Date
    pageNumber?: number;
}

export class Permission {
    constructor(){
        this.name = "";
        this.superadmin = false;
        this.hidden = false;
        this.label = "";
        this.description = "";

        this.categoryNameRaw = "";
        this.categoryName = "";
        this.permissionName = "";

        this.selected = false;
    }
    name:string
    superadmin:boolean
    hidden:boolean
    label:string
    description:string

    categoryNameRaw:string;
    categoryName:string
    permissionName:string

    selected:boolean;
}

export class PermissionUser {
    constructor() {
        this.personId = "";
        this.permissionName = "";
    }
    personId:string
    permissionName:string
}

export interface Company extends IPageable {
    companyId:string
    companyName:string
    deleted:boolean
}

export interface CompanyConfigMap {
    use_smarty_address:CompanyConfig;
    use_transactional_activities:CompanyConfig;
    company_crm_type:CompanyConfig;
    company_use_amazon_connect:CompanyConfig;
    crm_type:CompanyConfig;
    prospect_score_type:CompanyConfig;
}

export interface CompanyConfig {
    guid:string;
    companyId:string;
    name:string;
    display:string;
    type:string;
    value:string;
}

export interface OrganizationModel extends IContactable, IPageable {
    organizationId:string
    organizationName:string
    companyId:string
    description:string
    website:string
    type:ReferralSource
    deleted:boolean
}

export interface IUnit extends INameDisplay  {
    unitId:string;
    externalUnitId:string;
    name:string;
    bed:string;
    communityId:string;
    companyId:string;
    floorplanId:string;
    deleted:boolean;
    occupied:boolean;
    selected?:boolean;
    occupant:Person|null;
    
    snapshot?:OccupancySnapshot|null;
    reserved:string;
    notes?:any[];
    loadingNotes?:boolean;
}

export class Organization implements OrganizationModel {
    public static fromJson(obj?:any|null){
        let org = new Organization();

        org.organizationId = obj.organizationId;
        org.organizationName = obj.organizationName;
        org.companyId = obj.companyId;
        org.description = obj.description;
        org.website = obj.website;
        org.deleted = obj.deleted;
        
        if(obj.firstContact != null){
            org.firstContact = new Date(obj.firstContact);
        }
        if(obj.lastContact != null){
            org.lastContact = new Date(obj.lastContact);
        }
        
        org.primaryEmail = obj.primaryEmail;
        org.primaryPhone = obj.primaryPhone;

        org.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
        org.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
        org.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
            p.number = p.number ? p.number.replaceAll(".", "") : "";
            return p;
        }) : [];

        org.type = obj.type;
        
        if(obj.pageNumber != null){
            org.pageNumber = obj.pageNumber;
        }
                    
        return org;
    }
    
    constructor(){
        this.organizationId = "";
        this.organizationName = "";
        this.companyId = "";
        this.description = "";
        this.website = "";
        this.type = {} as ReferralSource;
        this.deleted = false;
        this.mailAddresses = [];
        this.emailAddresses = [];
        this.phoneNumbers = [];
        this.primaryPhone = {} as Phone;
        this.primaryEmail = {} as Email;
    }
    organizationId:string
    organizationName:string
    companyId:string
    description:string
    website:string
    type:ReferralSource
    deleted:boolean
    lastContact!:Date;
    firstContact!:Date;
    
    mailAddresses:Address[]
    emailAddresses:Email[]
    phoneNumbers:Phone[]    
    pageNumber?:number

    get id() {
        return this.organizationId;
    }
    
    get nameDisplay(): string {
        return this.organizationName;
    }

    primaryPhone:Phone
    primaryEmail:Email
    public static text_length = 255;
    
    get nameAbbreviation(): string {
        if(this.nameDisplay.length > 0){
            let nameArr = this.nameDisplay
                .split(" ")
                .filter(a=>a.length > 0)
                .filter(a=>a.toLowerCase() != "the")
                .filter(a=>a.toLowerCase() != "of")
                .filter(a=>a.toLowerCase() != "for")
                .filter(a=>a.toLowerCase() != "in")
                .filter(a=>a.toLowerCase() != "a");
            if(nameArr.length == 2){
                return nameArr[0][0] + nameArr[1][0];
            }else{
                return nameArr[0][0];
            }
        }else{
            return "?";
        }
    }
}

export abstract class AbstractCommunity implements IContactable, IPageable {
    constructor(){
        this.communityName = "";
        this.companyId = "";
        this.companyName = "";
        this.timezone = "";
        this.deleted = false;
        this.mailAddresses = [];
        this.emailAddresses = [];
        this.phoneNumbers = [];
        this.primaryEmail = null;
        this.primaryPhone = null;
    }
    communityName:string
    companyId:string
    companyName:string
    timezone:string
    deleted:boolean
    
    mailAddresses:Address[]
    emailAddresses:Email[]
    phoneNumbers:Phone[]

    primaryPhone:Phone|null;
    primaryEmail:Email|null;
    
    pageNumber?: number | undefined;

    abstract get id():string;
    
    get nameDisplay(): string {
        return this.communityName;
    }
    
    get nameAbbreviation(): string {
        if(this.nameDisplay.length > 0){
            let nameArr = this.nameDisplay
                .split(" ")
                .filter(a=>a.length > 0)
                .filter(a=>a.toLowerCase() != "the")
                .filter(a=>a.toLowerCase() != "of")
                .filter(a=>a.toLowerCase() != "for")
                .filter(a=>a.toLowerCase() != "in")
                .filter(a=>a.toLowerCase() != "a");
            if(nameArr.length == 2){
                return nameArr[0][0] + nameArr[1][0];
            }else{
                return nameArr[0][0];
            }
        }else{
            return "?";
        }
    }
    
    abstract get communityType(): "Referred"|"Company";
}

export class ReferrerCommunity extends AbstractCommunity {
    public static fromJson(obj?:any|null){
        let refCom = new ReferrerCommunity();
        if(obj == null){
            return refCom;
        }else{
            refCom.referrerCommunityId = obj.referrerCommunityId;
            refCom.communityName = obj.communityName;
            refCom.companyId = obj.companyId;
            refCom.description = obj.description;
            refCom.website = obj.website;
            refCom.pricing = obj.pricing;
            refCom.levelOfCare = obj.levelOfCare;
            refCom.servicesOffered = obj.servicesOffered;
            refCom.notes = obj.notes;

            refCom.deleted = obj.deleted;
        
            refCom.primaryEmail = obj.primaryEmail;
            refCom.primaryPhone = obj.primaryPhone;

            refCom.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
            refCom.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
            refCom.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
                p.number = p.number ? p.number.replaceAll(".", "") : "";
                return p;
            }) : [];

            refCom.productTypes = obj.productTypes;

            refCom.productTypesDisplay = "";
            for(let pt of refCom.productTypes as ProductType[]){
                if (refCom.productTypesDisplay.length > 0) {
                    refCom.productTypesDisplay += ", ";
                }
                refCom.productTypesDisplay += pt.name;
            }

            refCom.communityAttributes = obj.communityAttributes ? obj.communityAttributes.map((o:any) => ReferenceAttributeCommunity.fromJson(o)) : [];

            return refCom;
        }
    }
    constructor(){
        super();
        this.referrerCommunityId = "";
        this.description = "";
        this.website = "";
        this.pricing = "";
        this.levelOfCare = "";
        this.servicesOffered = "";
        this.notes = "";
        this.mailAddresses = [];
        this.emailAddresses = [];
        this.phoneNumbers = [];
        this.primaryPhone = null;
        this.primaryEmail = null;

        this.productTypes = [];
        this.productTypesDisplay = "";
    }
    referrerCommunityId:string
    description:string
    website:string
    pricing:string
    levelOfCare:string
    servicesOffered:string
    notes:string 
    communityAttributes: ReferenceAttributeCommunity[] = [];

    get id() {
        return this.referrerCommunityId;
    }

    productTypes:ProductType[];
    productTypesDisplay:string;

    public static text_length = 255;
    
    get communityType():"Referred" {
        return "Referred";
    }
}

export class Community extends AbstractCommunity {
    public static fromJson(obj?:any|null){
        let community = new Community();
        if(obj == null){
            return community;
        }else{
            community.communityId = obj.communityId;
            community.communityName = obj.communityName;
            community.companyId = obj.companyId;
            community.companyName = obj.companyName;
            community.timezone = obj.timezone;
            community.deleted = obj.deleted;
            community.selected = obj.selected ? true : false;
            community.acInstanceId = obj.acInstanceId;
            community.acUrl = obj.acUrl;
            community.kcUrl = obj.keycloakUrl;
            community.pinpointNumber = obj.pinpointNumber;
            community.usesAc = obj.usesAc;
    
            community.communityAddress1 = obj.communityAddress1;
            community.communityAddress2 = obj.communityAddress2;
            community.communityCity = obj.communityCity;
            community.communityState = obj.communityState;
            community.communityZip = obj.communityZip;
            community.communityWebsite = obj.communityWebsite;
            community.description = obj.description;
            
            // community.timezone = obj.timezone;

            community.mailAddresses = obj.mailAddresses ? obj.mailAddresses : [];
            community.emailAddresses = obj.emailAddresses ? obj.emailAddresses : [];
            community.phoneNumbers = obj.phoneNumbers ? obj.phoneNumbers.map((p:any) => {
                p.number = p.number ? p.number.replaceAll(".", "") : "";
                return p;
            }) : [];
            
            let primaryPhone = community.phoneNumbers.find(a=>a.is_primary);
            community.primaryPhone = primaryPhone ? primaryPhone : null;
            let primaryEmail = community.emailAddresses.find(a=>a.is_primary);
            community.primaryEmail = primaryEmail ? primaryEmail : null;
        
            if(obj.pageNumber != null){
                community.pageNumber = obj.pageNumber;
            }
            
            return community;
        }
    }
    
    constructor(){
        super();
        this.communityId = "";
        this.pinpointNumber = "";
        this.acInstanceId = "";
        this.acUrl = "";
        this.kcUrl = "";
        this.usesAc = false;
        this.selected = false;
        this.disabled = false;
        this.mailAddresses = [];
        this.emailAddresses = [];
        this.phoneNumbers = [];
        this.communityAddress1 = "";
        this.communityAddress2 = "";
        this.communityCity = "";
        this.communityState = "";
        this.communityZip = "";
        this.timezone = "";
        this.communityWebsite = "";
        this.description = "";

    }
    communityId:string
    pinpointNumber:string
    acInstanceId:string
    acUrl:string
    kcUrl:string
    usesAc:boolean
    selected:boolean
    disabled:boolean

    communityAddress1:string
    communityAddress2:string
    communityCity:string
    communityState:string
    communityZip:string
    communityWebsite: string
    description: string

    get id() {
        return this.communityId;
    }
    
    get communityType():"Company" {
        return "Company";
    }
}

export class MarketingMaterial implements IPageable, PhotoLink {//, INameDisplay
    public static fromJson(obj:any|undefined){
        if(obj){
            return new MarketingMaterial(obj);
        }else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj){
            this.marketingMaterialId = obj.marketingMaterialId;
            this.guid = this.marketingMaterialId;
            this.filetype = obj.uploadFiletype;
            this.fileId = obj.fileId;
            this.title = obj.title;
            this.fileType = obj.fileType as MarketingFileType;
            // this.link = obj.link;
            this.creationDate = new Date(obj.creationDate);
            this.modifiedDate = new Date(obj.modifiedDate);
            this.communityId = obj.communityId;
            this.uploadPerson = Person.fromJson(obj.uploadPerson);
            this.allowAsAttachment = !!obj.allowAsAttachment;
            
            if(obj.stackTrace){
                this.stackTrace = obj.stackTrace;
            }
            if(obj.message){
                this.message = obj.message;
            }
        }else{
            this.marketingMaterialId = "";
            this.guid = this.marketingMaterialId;
            this.title = "";
            this.fileType = {} as MarketingFileType;
            // this.link = "";
            this.filetype = "";
            this.fileId = "";
            this.creationDate = new Date();
            this.modifiedDate = new Date();
            this.communityId = null;
            this.uploadPerson = null;
            this.allowAsAttachment = false;
        }
    }
    message: any
    guid: string
    filetype: "png" | "jpg" | "jpeg" | "pdf" | ""
    fileId: string
    loaded?: boolean | undefined
    stackTrace?: string | undefined
    
    marketingMaterialId:string;
    title:string;
    fileType:MarketingFileType;
    // link:string;
    creationDate:Date;
    modifiedDate:Date;
    communityId:string|null;
    uploadPerson:Person|null;
    allowAsAttachment:boolean;
    
    pageNumber?: number | undefined;
}

export class UploadFile implements IPageable, PhotoLink {//, INameDisplay
    public static fromJson(obj:any|undefined){
        if(obj){
            return new UploadFile(obj);
        }else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj){
            this.uploadFileId = obj.uploadFileId;
            this.guid = this.uploadFileId;
            this.filetype = obj.uploadFiletype;
            this.fileId = obj.fileId;
            this.title = obj.title;
            this.folderType = obj.folderType as MarketingFileType;
            this.creationDate = new Date(obj.creationDate);
            this.modifiedDate = new Date(obj.modifiedDate);
            this.personId = obj.personId;
            this.uploadPerson = Person.fromJson(obj.uploadPerson);
            this.allowAsAttachment = !!obj.allowAsAttachment;
            
            if(obj.stackTrace){
                this.stackTrace = obj.stackTrace;
            }
            if(obj.message){
                this.message = obj.message;
            }
        }else{
            this.uploadFileId = "";
            this.guid = this.uploadFileId;
            this.title = "";
            this.folderType = {} as MarketingFileType;
            this.filetype = "";
            this.fileId = "";
            this.creationDate = new Date();
            this.modifiedDate = new Date();
            this.personId = null;
            this.uploadPerson = null;
            this.allowAsAttachment = false;
        }
    }
    message: any
    guid: string
    filetype: "png" | "jpg" | "jpeg" | "pdf" | ""
    fileId: string
    loaded?: boolean | undefined
    stackTrace?: string | undefined
    
    uploadFileId:string;
    title:string;
    folderType:MarketingFileType;
    creationDate:Date;
    modifiedDate:Date;
    personId:string|null;
    uploadPerson:Person|null;
    allowAsAttachment:boolean;
    
    pageNumber?: number | undefined;
}

export class UploadFileReferrerCommunity implements IPageable, PhotoLink {//, INameDisplay
    public static fromJson(obj:any|undefined){
        if(obj){
            return new UploadFileReferrerCommunity(obj);
        }else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj){
            this.uploadFileId = obj.uploadFileId;
            this.guid = this.uploadFileId;
            this.filetype = obj.uploadFiletype;
            this.fileId = obj.fileId;
            this.title = obj.title;
            this.folderType = obj.folderType as MarketingFileType;
            this.creationDate = new Date(obj.creationDate);
            this.modifiedDate = new Date(obj.modifiedDate);
            this.referrerCommunityId = obj.referrerCommunityId;
            this.uploadPerson = Person.fromJson(obj.uploadPerson);
            this.allowAsAttachment = !!obj.allowAsAttachment;
            
            if(obj.stackTrace){
                this.stackTrace = obj.stackTrace;
            }
            if(obj.message){
                this.message = obj.message;
            }
        }else{
            this.uploadFileId = "";
            this.guid = this.uploadFileId;
            this.title = "";
            this.folderType = {} as MarketingFileType;
            this.filetype = "";
            this.fileId = "";
            this.creationDate = new Date();
            this.modifiedDate = new Date();
            this.referrerCommunityId = null;
            this.uploadPerson = null;
            this.allowAsAttachment = false;
        }
    }
    message: any
    guid: string
    filetype: "png" | "jpg" | "jpeg" | "pdf" | ""
    fileId: string
    loaded?: boolean | undefined
    stackTrace?: string | undefined
    
    uploadFileId:string;
    title:string;
    folderType:MarketingFileType;
    creationDate:Date;
    modifiedDate:Date;
    referrerCommunityId:string|null;
    uploadPerson:Person|null;
    allowAsAttachment:boolean;
    
    pageNumber?: number | undefined;
}


export class Event implements IPageable {
    public static fromJson(obj:any|undefined){
        if(obj){
            return new Event(obj);
        }else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj){
            this.eventId = obj.eventId;

            this.name = obj.name;
            this.salesCounselor = obj.salesCounselor;

            if (obj.eventDate !=null){
                this.eventDate = new Date(obj.eventDate);
            } else {
                this.eventDate = null;
            }

            if (obj.startTime !=null){
                this.startTime = new Date(obj.startTime);
            } else {
                this.startTime = null;
            }

            if (obj.endTime !=null){
                this.endTime = new Date(obj.endTime);
            } else {
                this.endTime = null;
            }

            if (obj.creationDate !=null){
                this.creationDate = new Date(obj.creationDate);
            } else {
                this.creationDate = null;
            }

            if (obj.modifiedDate !=null){
                this.modifiedDate = new Date(obj.modifiedDate);
            } else {
                this.modifiedDate = null;
            }

            this.address = {is_primary:true, address_1:obj.address1, address_2:obj.address2, city:obj.city, state:obj.state, zip:obj.zip, timezone:obj.timezone, deleted:false};

            this.timeOnlyStartDisplay = obj.timeOnlyStartDisplay;
            this.timeOnlyEndDisplay = obj.timeOnlyEndDisplay;

            this.budget = obj.budget;
            this.actualCost = obj.actualCost;

            this.location = obj.location;
            this.description = obj.description;

            if(obj.community){
                this.community = Community.fromJson(obj.community);
            }else{
                this.community = null;
            }

        }else{
            this.eventId = "";

            this.name = "";
            this.salesCounselor = null;

            this.eventDate = null
            this.startTime = null;
            this.endTime = null;

        
            this.address = {is_primary:true, address_1:"", address_2:"", city:"", state:"", zip:"", timezone:"", deleted:false}
            
            this.timeOnlyStartDisplay = "";
            this.timeOnlyEndDisplay = "";

            this.budget = null;
            this.actualCost = null;

            this.location = "";
            this.description = "";

            this.creationDate = null;
            this.modifiedDate = null;
            this.community = null;
        }
    }
    eventId:string;

    name: any
    salesCounselor:Person|null;

    eventDate:Date|null;
    startTime:Date|null;
    endTime:Date|null;

    address:Address;

    timeOnlyStartDisplay:string|null;
    timeOnlyEndDisplay:string|null;

    budget:number|null;
    actualCost:number|null;

    location:String;
    description:String;

    creationDate:Date|null;
    modifiedDate:Date|null;
    community:Community|null;
    
    pageNumber?: number | undefined;    
}

export class EventInvite {
    public static fromJson(obj:any|undefined){
        if(obj){
            return new EventInvite(obj);
        }else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj){
            this.inviteId = obj.inviteId;
            this.eventId = obj.eventId;
            this.personId = obj.personId;
            this.messageId = obj.messageId;
            this.sent = obj.sent;
            this.delivered = obj.delivered;
            this.opened = obj.opened;
            this.clicked = obj.clicked;
            this.bounced = obj.bounced;
        }else{
            this.inviteId = "";
            this.eventId = "";
            this.personId = "";
            this.messageId = "";
            this.sent = false;
            this.delivered = false;
            this.opened = false;
            this.clicked = false;
            this.bounced = false;
        }
    }

    inviteId:string;
    eventId:string;
    personId:string;
    messageId:string;
    sent:boolean;
    delivered:boolean;
    opened:boolean;
    clicked:boolean;
    bounced:boolean;
}

export class Floorplan implements IPageable {
    constructor (){
        this.floorplanId = "";
        this.name = "";
        this.bedCount = null;
        this.bathCount = null;
        this.squareFeet = null;
        this.privacy = "";
        this.occupancyCount = null;
        //this.communityId = "";
        this.community = new Community();
        this.companyId = "";
        this.deleted = false;

        this.amenities = [];
        this.marketRates = [];
        this.photoIds = [];
    }
    floorplanId:string;
    name:string;
    bedCount:number|null;
    bathCount:number|null;
    squareFeet:number|null;
    privacy:string;
    occupancyCount:number|null;
    //communityId:string;
    community:Community;
    companyId:string;
    deleted:boolean;

    amenities:Amenity[];
    marketRates:MarketRate[];
    photoIds:PhotoLink[];
    
    pageNumber?: number | undefined;
}

export interface Amenity {
    amenityId?:string;
    floorplalnId?:string;
    value?:string;
    deleted?:boolean;
}

export class Unit {
    constructor (obj?:IUnit|null){
        this.snapshot = null;
        
        if(obj){
            this.unitId = obj.unitId;
            this.externalUnitId = obj.externalUnitId;
            this.name = obj.name;
            this.bed = obj.bed;
            this.communityId = obj.communityId;
            this.companyId = obj.companyId;
            this.floorplanId = obj.floorplanId;
            this.deleted = obj.deleted;
            this.occupied = obj.occupied;
            this.selected = false;
            this.occupant = Person.fromJson(obj.occupant);
            if(obj.snapshot){
                this.snapshot = new OccupancySnapshot(obj.snapshot);
            }
            this.reserved = obj.reserved;
            if(obj.notes){
                this.notes = obj.notes;  
                this.notes.forEach(n=>{
                    if(n.creator){
                        n.creator = Person.fromJson(n.creator);
                    }
                })                  
            }else{
                this.notes = [];
            }
        }else{
            this.unitId = "";
            this.externalUnitId = "";
            this.name = "";
            this.bed = "";
            this.communityId = "";
            this.companyId = "";
            this.floorplanId = "";
            this.deleted = false;
            this.occupied = false;
            this.selected = false;
            this.occupant = null;
            this.reserved = "";
            this.notes = [];
        }
    }
    
    unitId:string;
    externalUnitId:string;
    name:string;
    bed:string;
    communityId:string;
    floorplanId:string;
    companyId:string;
    deleted:boolean;
    occupied:boolean;
    selected?:boolean;
    occupant:Person|null;
    
    notes?:any[];
    loadingNotes?:boolean;
    snapshot?:OccupancySnapshot|null;
    reserved:string;
    
    get nameDisplay(): string {
        return this.name+this.bed;
    }
    get nameAbbreviation(): string {
        return this.nameDisplay;
    }
    get id():string {
        return this.unitId;
    }
}

export class OccupancySnapshot {
    constructor (obj?:any|null){
        if(obj){
            this.snapshotId = obj.snapshotId;
            this.datestamp = new Date(obj.datestamp);
            if(obj.occupantJson){
                this.occupant = Prospect.fromJson(JSON.parse(obj.occupantJson));
            }else{
                this.occupant = null;
            }
            if(obj.occupancyChangeOn != null){
                this.occupancyChangeOn = new Date(obj.occupancyChangeOn);
            }else{
                this.occupancyChangeOn = null;
            }
            if(obj.moveOutScheduled != null){
                this.moveOutScheduled = new Date(obj.moveOutScheduled);
            }else{
                this.moveOutScheduled = null;
            }
            this.occupantInfo = ProspectExternalInfo.fromJson(obj.occupantInfo);
    
            this.reservedOccupant = Prospect.fromJson(obj.reservedOccupant);
            if(obj.reservedOn != null){
                this.reservedOn = new Date(obj.reservedOn);
            }else{
                this.reservedOn = null;
            }
            
            if(obj.notesJson){
                this.notes = JSON.parse(obj.notesJson);
                this.notes.forEach(n=>{
                    n.creator = Person.fromJson(n.creator);
                })
            }else{
                this.notes = [];
            }
            
            if(obj.occupantStatus){
                this.occupantStatus = obj.occupantStatus;
            }else{
                this.occupantStatus = "Active";
            }
            if(obj.occupantAlertStatus){
                this.occupantAlertStatus = obj.occupantAlertStatus;
            }else{
                this.occupantAlertStatus = "";
            }
            

            this.locked = obj.locked;
            if(obj.lockChangedOn != null){
                this.lockChangedOn = new Date(obj.lockChangedOn);
            }else{
                this.lockChangedOn = null;
            }
    
            this.floorplanId = obj.floorplanId;

            this.companyId = obj.companyId;

            this.communityId = obj.communityId;
        }else{
            this.snapshotId = "";
            this.datestamp = new Date();
            this.occupant = null;
            this.occupancyChangeOn = null;
            this.occupantInfo = null;
            this.occupantStatus = "";
            this.occupantAlertStatus = "";
            this.notes = [];
            
            this.reservedOccupant = null;
            this.reservedOn = null;
            this.moveOutScheduled = null;

            this.locked = false;
            this.lockChangedOn = null;
    
            this.floorplanId = null;

            this.companyId = "";

            this.communityId = "";
        }
    }

    snapshotId:string;
    datestamp:Date;
    occupant:Prospect|null;
    occupantInfo:ProspectExternalInfo|null;
    occupancyChangeOn:Date|null;
    occupantStatus:string;
    occupantAlertStatus:string;
    
    reservedOccupant:Prospect|null;
    reservedOn:Date|null;
    moveOutScheduled:Date|null;

    locked:boolean;
    lockChangedOn:Date|null;
    
    floorplanId:string|null;

    companyId:string;

    communityId:string;
    
    floorplan?:Floorplan;
    
    notes:any[];
}

export interface OccupancyAggragate {
    onLeave:number
    onAlert:number
    inRehab:number
    hospitalized:number
    deposits:number
    reservations:number
    scheduledMoveIns:number
    scheduledMoveOuts:number
    moveIns:number
    moveOuts:number
    onLeaveMTD:number
    onAlertMTD:number
    inRehabMTD:number
    hospitalizedMTD:number
    depositsMTD:number
    reservationsMTD:number
    scheduledMoveInsMTD:number
    scheduledMoveOutsMTD:number
    moveInsMTD:number
    moveOutsMTD:number
    censusCount:number,
    censusDayCount:number,
    censusToDate:number,
}
export class OccupancySummaryOld {
    constructor (obj?:any|null){
        if(obj){
            this.summaryId = obj.summaryId;
    
            this.datestamp = new Date(obj.datestamp);

            this.bedCount = obj.bedCount;
            this.unitCount = obj.unitCount;
            this.residentCount = obj.residentCount;

            this.occupiedCount = obj.occupiedCount;
            this.occupiedPct = obj.occupiedPct;

            this.vacancyCount = obj.vacancyCount;
            this.vacancyPct = obj.vacancyPct;

            this.reservedCount = obj.reservedCount;
            this.reservedPct = obj.reservedPct;

            this.lockedCount = obj.lockedCount;
            this.lockedPct = obj.lockedPct;

            this.offCensusCount = obj.offCensusCount;
            this.offCensusPct = obj.offCensusPct;
        }else{
            this.summaryId = "";
    
            this.datestamp = new Date();
    
            this.bedCount = 0;
            this.unitCount = 0;
    
            this.residentCount = 0;

            this.occupiedCount = 0;
            this.occupiedPct = 0;

            this.vacancyCount = 0;
            this.vacancyPct = 0;

            this.reservedCount = 0;
            this.reservedPct = 0;

            this.lockedCount = 0;
            this.lockedPct = 0;

            this.offCensusCount = 0;
            this.offCensusPct = 0;
        }
    }
    
    summaryId:string;
    
    datestamp:Date;

    bedCount:number;
    unitCount:number;
    
    residentCount:number;

    occupiedCount:number;
    occupiedPct:number;

    vacancyCount:number;
    vacancyPct:number;

    reservedCount:number;
    reservedPct:number;

    lockedCount:number;
    lockedPct:number;

    offCensusCount:number;
    offCensusPct:number;
}


export class MarketRate {
    constructor (){
        this.marketRateId = "";
        this.floorplalnId = "";
        this.first = null;
        this.second = null;

        this.deleted = false;
    }

    marketRateId?:string;
    floorplalnId:string;
    first:number|null;
    second:number|null;
    startDate!:Date;
    deleted:boolean;
}

export class MessageTemplate implements IPageable {
    constructor (obj?:any|null){        
        if(obj){
            this.templateId = obj.templateId;
            this.name = obj.name;
            this.description = obj.description;
            this.subject = obj.subject;
            this.type = obj.type;
            this.message = obj.message;
            
            const user = User.fromJson(obj.createdBy);
            if(user != null){
                this.createdBy = user;
            }else{
                this.createdBy = new User();
            }
            this.creationDate = new Date(obj.creationDate);
            
            if(obj.attachments){
                this.attachments = obj.attachments.map((a:any)=>new MessageTemplateAttach(a));
            }
        
            if(obj.pageNumber != null){
                this.pageNumber = obj.pageNumber;
            }
        }else{
            this.templateId = "";
            this.name = ""
            this.description = "";
            this.subject = "";
            this.type = "";

            this.message = "";
    
            this.createdBy = new User();
            this.creationDate = new Date("Invalid");
        }
    }
    
    templateId:string
    name:string
    description:string
    subject:string
    type:string

    message:string
    
    createdBy:User
    creationDate:Date    
    attachments?:MessageTemplateAttach[];
    pageNumber?: number | undefined;
}
export class MessageTemplateAttach {
    constructor (obj?:any|null){
        if(obj){
            this.guid = obj.guid;
            this.filename = obj.filename;
            this.contentType = obj.contentType;
            this.contentDisposition = obj.contentDisposition;
            this.deleted = obj.deleted;
            this.file = obj.file;
            this.companyId = obj.companyId;
        }else{
            this.guid = "";
            this.filename = "";
            this.contentType = "";
            this.contentDisposition = "";
            this.deleted = false;
        }
    }
    guid:string;
    filename:string;
    contentType:string;
    contentDisposition:string;
    deleted:boolean;
    file?:File;
    companyId?:string;
}
export class PersonNote {
    public static fromJson(obj: any): PersonNote  {
        let note = new PersonNote();

        note.guid = obj.guid;
        note.datestamp = new Date(obj.datestamp);
        note.note = obj.note;
        note.type = obj.type;
        note.personType = obj.personType;
        note.person = Person.fromJson(obj.person) as Person;
        note.user = User.fromJson(obj.user) as User;
        
        return note;
    }
    private constructor (){
        this.guid = "";
        this.datestamp = new Date();
        this.note = "";
        this.type = "";
        this.personType = "";
        this.person = Person.fromJson({}) as Person;
        this.user = User.fromJson({}) as User;
    }
    guid:string;
    datestamp:Date;
    note:string;
    type:string;
    person:Person;
    personType:string;
    user:User;
}

export class ReportRequest {
    public static fromJson(obj:any|undefined){
        if(obj){
            return new ReportRequest(obj);
        } else{
            return null;
        }
    }
    constructor (obj?:any){
        if(obj){
            this.company_id = obj.company_id;
            this.report_type = obj.report_type;
            this.community_id = obj.community_id
            this.start_date = obj.startCutoff;
            this.end_date = obj.endCutoff;
            this.person_ids = obj.person_ids;
            this.month = obj.month;
            this.year = obj.year;
            this.types = obj.types;
            this.types_2 = obj.types_2;
            this.additional_text = obj.additional_text;
        }else{
            this.company_id = "";
            this.report_type = "";
            this.community_id = "";
            this.start_date = new Date();
            this.end_date = new Date();
            this.person_ids = [];
            this.month = "";
            this.year = "";
            this.types = [];
            this.types_2 = [];
            this.additional_text = "";
        }
    }
    
  
    company_id:String;
    report_type:String;
    community_id:String;

    start_date:Date;
    end_date:Date;
    person_ids?:string[];
    types?:string[];
    types_2?:string[];
    additional_text:String;
    month:String;
    year:String;
  }
  
export class ReportDefinition implements INameDisplay {
    public static fromJson(obj: any): ReportDefinition  {
        let def = new ReportDefinition();

        def.guid = obj.guid;
        def.reportName = obj.report_name;
        def.reportType = obj.reportType;
        def.reportType2 = obj.report_type2;
        def.companyId = obj.companyId;
        def.reportTitle = obj.report_title;
        
        return def;
    }
    private constructor (){
        this.guid = "";
        this.reportName = "";
        this.reportType = "";
        this.reportType2 = "";
        this.companyId = "";
        this.reportTitle = "";
    }
    guid:string;
    reportName:string;
    reportType:string;
    reportType2:string;
    reportTitle:string;
    companyId:string;
    get id(): string {
        return this.guid;
    }
    get nameAbbreviation(): string {
        return this.reportName.slice(0,1);
    }
    get nameDisplay(): string {
        return this.reportName;
    }
}

export class CommunityOfInterest implements INameDisplay, IPageable {
    public static fromJson(obj:any){
        let community = new CommunityOfInterest();

        community.guid = obj.guid;
        community.personId = obj.personId;
        community.communityId = obj.communityId;
        community.companyId = obj.companyId;
        community.referrerCommunity = ReferrerCommunity.fromJson(obj.referrerCommunity);
        
        community.chosen = !!obj.chosen;
        if(obj.chosenDate){
            community.chosenDate = new Date(obj.chosenDate);
        }
        
        community.rejected = !!obj.rejected;
        if(obj.rejectedDate){
            community.rejectedDate = new Date(obj.rejectedDate);
        }
        
        community.deleted = !!obj.deleted;
        if(obj.deletedDate){
            community.deletedDate = new Date(obj.deletedDate);
        }

        community.addedType = obj.addedType as "manual"|"auto";
        community.addedDate = new Date(obj.addedDate);
        
        return community;
    }
    
    constructor (){
        this.guid = "";
        this.personId = "";
        this.communityId = "";
        this.companyId = "";
        this.referrerCommunity = new ReferrerCommunity();
        this.chosen = false;
        this.rejected = false;
        this.deleted = false;
        this.addedType = "manual";
        this.addedDate = new Date();
    }
    
    guid:string;
    personId:string;
    communityId:string;
    companyId:string;
    referrerCommunity:ReferrerCommunity;
    
    addedType:"manual"|"auto";
    addedDate:Date;
    
    chosen:boolean;
    chosenDate?:Date;
    
    rejected:boolean;
    rejectedDate?:Date;
    
    deleted:boolean;
    deletedDate?:Date;
    
    pageNumber?: number | undefined;
    
    get id(): string {
        return this.referrerCommunity.id;
    }
    get nameDisplay(): string {
        return this.referrerCommunity.nameDisplay;
    }
    get nameAbbreviation(): string {
        return this.referrerCommunity.nameAbbreviation;
    }
}

export class PlacementHistory implements INameDisplay, IPageable{
    public static fromJson(obj: any): PlacementHistory  {
        let def = new PlacementHistory();
        
        def.placementId = obj.placementId;
        def.placementDate = new Date(obj.placementDate)
        def.referrerCommunity = ReferrerCommunity.fromJson(obj.referrerCommunity);
        def.prospect = Person.fromJson(obj.prospect) as Person;
        def.placementBy = Person.fromJson(obj.placementBy) as Person;
        def.communityId = obj.communityId;
        def.companyId = obj.companyId;
        
        if(obj.deposit){
            def.deposit = ReserveDeposit.fromJson(obj.deposit);
        }
        
        return def;
    }
    private constructor (){
        this.placementId = "";
        this.communityId = "";
        this.companyId = "";
        this.placementDate = new Date();
        this.referrerCommunity = new ReferrerCommunity();
        this.prospect = new Person();
        this.placementBy = new Person();
    }
    
    placementId:string;
    prospect:Person;
    referrerCommunity:ReferrerCommunity;
    communityId:String;
    companyId:String;
    placementDate:Date;
    placementBy:Person;
    deposit?:ReserveDeposit;
    
    pageNumber?:number
    
    get id(): string {
        return this.placementId;
    }
    get nameAbbreviation(): string {
        return this.prospect.nameAbbreviation;
    }
    get nameDisplay(): string {
        return this.prospect.nameDisplay;
    }
}

export class ReserveDeposit {
    public static fromJson(obj: any): ReserveDeposit  {
        let def = new ReserveDeposit();

        def.depositId = obj.depositId;
        def.prospectId = obj.prospectId;
        def.companyId = obj.companyId;
        def.datestamp = new Date(obj.datestamp);
        def.deleted = obj.deleted;
        def.estMoveInDate = new Date(obj.estMoveInDate);
        def.monthlyRate = obj.monthlyRate;
        def.recurringMonthlySpecial = obj.recurringMonthlySpecial;
        def.recurringMonthlySpecialDescription = obj.recurringMonthlySpecialDescription;
        def.oneTimeSpecial = obj.oneTimeSpecial;
        def.oneTimeSpecialDescription = obj.oneTimeSpecialDescription;
        def.depositAmount = obj.depositAmount;
        def.depositReceivedDate = new Date(obj.depositReceivedDate);
        def.unitId = obj.unitId;
        def.unitIdReserveInProgress = obj.unitIdReserveInProgress;
        def.userId = obj.userId;
        def.communityId = obj.communityId;
        def.productType = obj.productType as ProductType;
        def.assignedUserId = obj.assignedUserId;
        def.preadmit = obj.preadmit;
        return def;
    }
    private constructor (){
        this.depositId = "";
        this.prospectId = "";
        this.companyId = "";
        this.datestamp = new Date();
        this.deleted = false;
        this.estMoveInDate = new Date();
        this.monthlyRate = 0;
        this.recurringMonthlySpecial = 0;
        this.recurringMonthlySpecialDescription = "";
        this.oneTimeSpecial = 0;
        this.oneTimeSpecialDescription = "";
        this.depositAmount = 0;
        this.depositReceivedDate = new Date();
        this.unitId = "";
        this.unitIdReserveInProgress = "";
        this.userId = "";
        this.communityId = "";
        this.productType = {score:0, name:"", guid:"", deleted: false} as ProductType;
        this.assignedUserId = "";
        this.preadmit = false;
    }
    
    depositId:string;
    prospectId:string;
    companyId:string;
    datestamp:Date;
    deleted:boolean;
    estMoveInDate:Date;
    monthlyRate:number;
    recurringMonthlySpecial:number;
    recurringMonthlySpecialDescription:string;
    oneTimeSpecial:number;
    oneTimeSpecialDescription:string;
    depositAmount:number;
    depositReceivedDate:Date;
    unitId:string;
    unitIdReserveInProgress:string;
    userId:string;
    communityId:string;
    productType:ProductType;
    assignedUserId:string;
    preadmit:boolean;
}

export class Report implements INameDisplay, IPageable{
    public static fromJson(obj: any): Report  {
        let def = new Report();
        
        def.reportId = obj.reportId;
        def.reportDate = new Date(obj.transDate)
        def.filename = obj.filename;
        def.status = obj.status;
        def.alias = obj.alias;
        def.companyId = obj.companyId;
        def.communityId = obj.communityId;
        def.personId = obj.personId;
        def.staffCreated = Person.fromJson(obj.staffCreated) as Person;
        def.csv_filename = obj.csv_filename;
        return def;
    }
    private constructor (){
        this.reportId = "";
        this.reportDate = new Date();
        this.filename = "";
        this.status = "";
        this.alias = "";
        this.companyId = "";
        this.communityId = "";
        this.personId = "";
        this.staffCreated = Person.fromJson({personId: "", firstname:"", lastname:""}) as Person;
        this.csv_filename = "";
    }
    reportId:string;
    reportDate:Date;
    filename:string;
    status:string;
    alias:string;
    companyId:string;
    communityId:string;
    personId:string;
    staffCreated:Person;
    csv_filename:string;

    pageNumber?:number
    
    get id(): string {
        return this.reportId;
    }
    get nameAbbreviation(): string {
        return this.alias.slice(0,1);
    }
    get nameDisplay(): string {
        return this.alias;
    }
}

//TODO: I don't know that we even need this.
//Bitshifted enum so users can have multiple types
export enum UserRole {
    None = 0,          //0000000
    Admin = 1,         //0000001
    User = 2,          //0000010
    Prospect = 4,      //0000100
    Influencer = 8,    //0001000
    Referrer = 16,     //0010000
    Staff = 32,        //0100000
    OtherContact = 64, //1000000
}