<mat-card appearance="outlined" class="mat-mr">
    <mat-card-title class="titleRow"><!-- class="nameRow"-->
        <person-chip [display]="prospect" size="small" header></person-chip>
        <span>Journey</span>
        <!-- <input type="text" [ngModel]="lead.firstname" (ngModelChange)="lead.firstname = $event" placeholder="First Name">
        <input type="text" [ngModel]="lead.middlename" (ngModelChange)="lead.middlename = $event" placeholder="Middle Name">
        <input type="text" [ngModel]="lead.lastname" (ngModelChange)="lead.lastname = $event" placeholder="Last Name"> -->
        <div class="widgetIcons">
            <fa-icon class="resize" [icon]="compressIcon" *ngIf="isExpanded" (click)="compressWidget()"></fa-icon>
            <fa-icon class="resize" [icon]="expandIcon" *ngIf="!isExpanded" (click)="expandWidget()"></fa-icon>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="journeyEvents">
            <mat-list role="list" *ngFor="let dateEvents of events">
                <mat-list-item role="listitem" class="dateRow" (click)="dateEvents.expanded = !dateEvents.expanded">
                    <span>
                        <span>{{dateEvents.date | date:'shortDate'}}</span>
                        <mat-icon *ngIf="dateEvents.expanded">chevron_right_icon</mat-icon>
                        <mat-icon *ngIf="!dateEvents.expanded">expand_more_icon</mat-icon>
                    </span>
                </mat-list-item>
                <ng-container *ngIf="dateEvents.expanded" >
                    <ng-container *ngFor="let event of dateEvents.events">
                        <journey-message [event]="event" *ngIf="getType(event.event_type) === 'message'"></journey-message>
                        <journey-new-prospect [event]="event" *ngIf="getType(event.event_type) === 'new-prospect'"></journey-new-prospect>
                        <journey-edit-prospect [event]="event" *ngIf="getType(event.event_type) === 'edit-prospect'"></journey-edit-prospect>
                        <journey-link-prospect [event]="event" *ngIf="getType(event.event_type) === 'link-prospect'"></journey-link-prospect>
                        <journey-unlink-prospect [event]="event" *ngIf="getType(event.event_type) === 'unlink-prospect'"></journey-unlink-prospect>
                        <journey-activity [event]="event" *ngIf="getType(event.event_type) === 'activity'"></journey-activity>
                        <journey-person-note [event]="event" *ngIf="getType(event.event_type) === 'person-note'"></journey-person-note>
                        <journey-todo [event]="event" *ngIf="getType(event.event_type) === 'todo'"></journey-todo>
                        <journey-close-prospect [event]="event" *ngIf="getType(event.event_type) === 'close-prospect'"></journey-close-prospect>
                        <journey-assessments [event]="event" *ngIf="getType(event.event_type) === 'assessments'"></journey-assessments>
                        <journey-preadmit [event]="event" *ngIf="getType(event.event_type) === 'preadmit'"></journey-preadmit>
                        <journey-not-admitted [event]="event" *ngIf="getType(event.event_type) === 'not-admitted'"></journey-not-admitted>
                        <journey-api-sync [event]="event" *ngIf="getType(event.event_type) === 'api-sync'"></journey-api-sync>
                        <journey-unit-reserved [event]="event" *ngIf="getType(event.event_type) === 'unit-reserved'"></journey-unit-reserved>
                        <journey-crm-import [event]="event" *ngIf="getType(event.event_type) === 'crm-import'"></journey-crm-import>
                        <journey-status-change [event]="event" *ngIf="getType(event.event_type) === 'status-change'"></journey-status-change>
                        <journey-set-on-alert [event]="event" *ngIf="getType(event.event_type) === 'set-on-alert'"></journey-set-on-alert>
                        <journey-alert-discontinued [event]="event" *ngIf="getType(event.event_type) === 'alert-discontinued'"></journey-alert-discontinued>
                        <journey-prospect-assignment [event]="event" *ngIf="getType(event.event_type) === 'assigned-prospect'"></journey-prospect-assignment>
                        <journey-placement-history [event]="event" *ngIf="getType(event.event_type) === 'placement-history'"></journey-placement-history>
                        <journey-community-of-interest [event]="event" *ngIf="getType(event.event_type) === 'community-of-interest'"></journey-community-of-interest>
                        <journey-generic [event]="event" *ngIf="getType(event.event_type) === 'generic'"></journey-generic>
                    </ng-container>
                    <!-- <mat-list-item role="listitem" *ngFor="let event of dateEvents.events">
                        <span matListIcon><fa-icon [icon]="getIcon(event.event_type)"></fa-icon></span>
                        <span matListItemLine class="subText">{{event.event_time | date:'shortTime'}}</span>
                        <span matListItemLine class="pipelineStage" matBadge="1" matBadgeSize="small" matBadgePosition="before" color="primary">Inquiry</span>
                        <span>
                            <div matListItemTitle>
                                <span [ngClass]="getColor(event.acting_person_id)" (click)="comingSoon()">{{getPersonName(event.acting_person)}}&nbsp;</span>
                                <span>{{getMessage(event)}}</span>
                                <span *ngIf="showTarget(event)" [ngClass]="getColor(event.target_person_id)" (click)="comingSoon()">&nbsp;{{getPersonName(event.target_person)}}</span>
                            </div>
                            <div matListItemLine class="subText" *ngIf="hasSubMessage(event)">
                                <span>{{getSubMessage(event)}}</span>
                            </div>
                        </span>
                    </mat-list-item> -->
                </ng-container>
            </mat-list>
            <div *ngIf="events.length <= 0">This prospect's Journey has not yet begun.</div>
        </div>
    </mat-card-content>
</mat-card>