<mat-card appearance="outlined" class="mat-mr">
    <mat-card-title class="titleRow">
        <span>
            <span>Placement History
                @if(searchTotal !== null){
                    ({{searchTotal}})
                }
            </span>
        </span>
        <span>
        </span>
        <div class="widgetIcons">
            <fa-icon class="resize" [icon]="compressIcon" *ngIf="isExpanded" (click)="compressWidget()"></fa-icon>
            <fa-icon class="resize" [icon]="expandIcon" *ngIf="!isExpanded" (click)="expandWidget()"></fa-icon>
        </div>
    </mat-card-title>
    <mat-card-content>
        <span class="flex-row search-row">
            <search-input [(filter)]="requestData" [matTooltip]="'Search Placement History.'"></search-input>

            <!-- <reset-search (click)="comingSoon()"></reset-search>
            <advanced-search class="ml-auto" [sessionStorageName]="'notificationsAdvancedSearch'" [numberOfColumns]="4" [comingSoon]="true"></advanced-search> -->
        </span>
        <div infiniteScroll [onScroll]="onScroll" [filter]="requestData()">
            <load-spinner variant="table"></load-spinner>
            <table mat-table [dataSource]="dataSource" class="mat-mr" [trackBy]="trackPlacement">
                <ng-container matColumnDef="user-icon">
                    <th mat-header-cell *matHeaderCellDef> Prospect </th>
                    <td mat-cell *matCellDef="let item" class="no-mobile-label mobile-flexrow" (click)="select(item)">
                        <div>
                            <person-icon [display]="item.prospect"></person-icon>
                            <span class="clipLines">{{item.prospect.nameDisplay}}</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="placementDate">
                    <th mat-header-cell *matHeaderCellDef> Date Placed </th>
                    <td mat-cell *matCellDef="let item" (click)="select(item)">
                        <div class="mobile-label"> Date Placed </div>
                        <span>
                            {{item.placementDate|date:"shortDate"}}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="est-move-in">
                    <th mat-header-cell *matHeaderCellDef> Estimated Move In </th>
                    <td mat-cell *matCellDef="let item" (click)="select(item)">
                        <div class="mobile-label"> Estimated Move In </div>
                        <span>
                            @if(item.deposit){
                                {{item.deposit?.estMoveInDate|date:"shortDate"}}
                            }@else {
                                Unknown Date
                            }
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="referrerCommunity.nameDisplay">
                    <th mat-header-cell *matHeaderCellDef > Community Placed </th>
                    <td mat-cell *matCellDef="let item" (click)="select(item)">
                        <div class="mobile-label"> Community </div>
                        <span>
                            <person-icon [display]="item.referrerCommunity" [name]="item.referrerCommunity.nameDisplay" size="small" class="auto-size"></person-icon>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="table-more">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let prospect">
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="comingSoon()">
                                <fa-icon [icon]="addInvoiceIcon"></fa-icon>
                                <span>Create Invoice</span>
                            </button>
                            <button mat-menu-item (click)="comingSoon()">
                                <fa-icon [icon]="editInvoiceIcon"></fa-icon>
                                <span>Edit Invoice</span>
                            </button>
                            <!-- <button mat-menu-item (click)="addActivity(prospect)" [disabled]="prospect.stagesCompleted == true" [matTooltip]="prospect.stagesCompleted == true ? 'The prospect completed all the stages. No more activities can be added.' : ''">
                                <fa-icon [icon]="addActivtyIcon"></fa-icon>
                                <span>Activity</span>
                            </button> -->
                            <!-- <button mat-menu-item (click)="seeSummary(prospect, 'reserve-deposit')" *ngIf="!prospect.isClosed">
                                <fa-icon [icon]="preadmitProspectIcon"></fa-icon>
                                <span>Preadmit</span>
                            </button> -->
                        </mat-menu>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns; let event;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                        <div>
                            There are currently no Placements
                            @if(user()?.company) { 
                                for {{user()?.company?.companyName}}
                            }
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>