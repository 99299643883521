<div *ngIf="currentUser()" [class.open]="open" class="menu">
  <div class="relativeItem">
      <!-- <div class="linkRow" (click)="comingSoon()">
      <fa-icon [icon]="searchIcon"></fa-icon>
      <span>Search</span>
    </div> -->
    <span [matMenuTriggerFor]="addMenu" #addMenuTrigger="matMenuTrigger" class="menuAnchor"></span> 
  </div>
  <div class="relativeItem">
    <div class="linkRow" (click)="openAddMenu();">
      <fa-icon [icon]="plusIcon"></fa-icon>
      <span>Add</span>
    </div>
    <mat-menu #addMenu="matMenu" class="navMenu">
      <mat-nav-list class="floatingMenu">
        <a mat-list-item routerLink="/events/edit" [queryParams]="{id:''}" (click)="clearSelectedEvent(); navigateResetState();">Event</a>
        <a mat-list-item routerLink="/marketingmaterial/edit" [queryParams]="{id:''}" (click)="clearSelectedMarketingMat(); navigateResetState();">Marketing Material</a>
        <a *ngIf="isReferrerCRM()" mat-list-item routerLink="/referrercommunity/edit" [queryParams]="{id:''}" (click)="clearSelectedCommunity(); navigateResetState();">Community</a>
        <a mat-list-item routerLink="/organization/edit" [queryParams]="{id:''}" (click)="clearSelectedOrg(); navigateResetState();">Organization</a>
        <a *ngIf="!isReferrerCRM()" mat-list-item routerLink="/prospect/edit" [queryParams]="{id:''}" (click)="navigateResetState();">Prospect</a>
        <a *ngIf="isReferrerCRM()" mat-list-item routerLink="/prospect/editrcrm" [queryParams]="{id:''}" (click)="navigateResetState();">Prospect</a>
        <a *ngIf="!isReferrerCRM()" mat-list-item routerLink="/referrer/edit" [queryParams]="{id:''}" (click)="clearSelectedRef(); navigateResetState();">Referrer</a>
      </mat-nav-list>
    </mat-menu>
    <!-- <div class="linkRow" routerLink="/dashboard" (click)="navigateResetState();">
        <fa-icon [icon]="searchIcon"></fa-icon>
        <span>Search</span>
    </div> -->
    <!-- <div class="linkRow" routerLink="/dashboard" (click)="navigateResetState();">
        <fa-icon [icon]="plusIcon"></fa-icon>
        <span>Add</span>
    </div> -->
    <mat-divider></mat-divider>
  </div>
  <div>
    <!-- <div class="linkRow" (click)="comingSoon()">
        <fa-icon [icon]="dashboardIcon"></fa-icon>
        <span>Dashboard</span>
    </div> -->
    <!-- <div class="linkRow" routerLink="/dashboard" [class.current]="isRoute('dashboard')" (click)="navigateResetState();">
        <fa-icon [icon]="dashboardIcon"></fa-icon>
        <span>Dashboard</span>
    </div> -->
  
    <mat-divider></mat-divider>
    
    <div class="linkRow" routerLink="/email" [class.current]="isRoute('email') && !routeContains('email/blast') && !routeContains('email/template')" (click)="navigateResetState();">
      <fa-icon [icon]="emailIcon"></fa-icon>
      <span>Email</span>
    </div>
    <!-- <div class="linkRow indentRow" routerLink="/email/blast" [class.current]="isRoute('email/blast')" (click)="navigateResetState();">
      <fa-icon [icon]="blastIcon"></fa-icon>
      <span>Blast</span>
    </div> -->
    <div class="linkRow indentRow" routerLink="/email/template" [class.current]="isRoute('email/template')" (click)="navigateResetState();">
      <fa-icon [icon]="blastIcon"></fa-icon>
      <span>Templates</span>
    </div>
    <!-- <div class="linkRow indentRow" routerLink="/email/blast" [class.current]="isRoute('email/blast')" (click)="navigateResetState();">
      <fa-icon [icon]="activityIcon"></fa-icon>
      <span>Blast</span>
    </div>
    <div class="linkRow indentRow" routerLink="/email/templates" [class.current]="isRoute('email/templates')" (click)="navigateResetState();">
      <fa-icon [icon]="activityIcon"></fa-icon>
      <span>Templates</span>
    </div> -->
    
    <mat-divider></mat-divider>
  </div>
  <div>
    <div class="linkRow" routerLink="/marketingmaterial" [class.current]="isRoute('marketingmaterial')" (click)="navigateResetState();">
      <fa-icon [icon]="marketingMaterialsIcon"></fa-icon>
      <span>Marketing Materials</span>
    </div>
    
    <mat-divider></mat-divider>
    
    <div class="linkRow" routerLink="/events" [class.current]="isRoute('events')" (click)="navigateResetState();">
      <fa-icon [icon]="eventsIcon"></fa-icon>
      <span>Events</span>
    </div>

    <mat-divider></mat-divider>

    <div class="linkRow" routerLink="/prospect" [class.current]="isRoute('prospect') && !routeContains('prospect/journey')" (click)="navigateResetState();">
        <fa-icon [icon]="prospectIcon"></fa-icon>
        <span>Prospects</span>
    </div>
    <div class="linkRow indentRow newWindowRow" routerLink="/activities/all" [class.current]="isRoute('activities/all')" (click)="navigateResetState();">
      <fa-icon [icon]="activityIcon"></fa-icon>
      <span>
        Planner
      </span>
      <fa-icon class="smallIcon" [icon]="newTabIcon" (click)="$event.stopPropagation();openRouteInNewTab('/activities/all');"></fa-icon>
    </div>
    <!-- <div class="linkRow indentRow" (click)="comingSoon();">
      <fa-icon [icon]="activityIcon"></fa-icon>
      <span>All Activities</span>
    </div> -->

    <div class="linkRow indentRow" routerLink="/prospect/journey" [class.current]="isRoute('prospect/journey')" >
      <fa-icon [icon]="journeyIcon"></fa-icon>
      <span>Journey</span>
    </div>
    @if(isReferrerCRM()){
      <mat-divider></mat-divider>

      <div class="linkRow" routerLink="/referrercommunity" [class.current]="isRoute('referrercommunity')" (click)="navigateResetState();">
        <fa-icon [icon]="occupancyIcon"></fa-icon>
        <span>Communities</span>
      </div>
      <div class="linkRow indentRow" routerLink="/referrerstaff" [class.current]="isRoute('referrerstaff')" (click)="navigateResetState();">
        <fa-icon [icon]="staffIcon"></fa-icon>
        <span>Staff</span>
      </div>
    }
    
    <mat-divider></mat-divider>
    
    <div class="linkRow" routerLink="/organization" [class.current]="isRoute('organization')" (click)="navigateResetState();">
      <fa-icon [icon]="organizationIcon"></fa-icon>
      <span>Organizations</span>
    </div>
    <div class="linkRow indentRow" routerLink="/referrer" [class.current]="isRoute('referrer') && !isRoute('referrercommunity') && !isRoute('referrerstaff')" (click)="navigateResetState();">
      <fa-icon [icon]="referrerIcon"></fa-icon>
      <span *ngIf="!isReferrerCRM()">Referrers</span>
      <span *ngIf="isReferrerCRM()">Contacts</span>
    </div>
    
    @if(isCommunityCRM()){
      <mat-divider></mat-divider>

      <div class="linkRow" routerLink="/occupancy" [class.current]="isRoute('occupancy')" (click)="navigateResetState();">
        <fa-icon [icon]="unitIcon"></fa-icon>
        <span>Occupancy</span>
      </div>
      <div class="linkRow indentRow" routerLink="/floorplan" [class.current]="isRoute('floorplan')" (click)="navigateResetState();">
        <fa-icon [icon]="floorplanIcon"></fa-icon>
        <span>Floor Plans</span>
      </div>
    }

    @if(isReferrerCRM()){
      <mat-divider></mat-divider>
      
      <div class="linkRow" routerLink="/placementhistory" [class.current]="isRoute('placementhistory')" (click)="navigateResetState();">
        <fa-icon [icon]="placementIcon"></fa-icon>
        <span>Placement History</span>
      </div>
    }
  </div>
    
  
  <mat-divider></mat-divider>
  
  <div class="relativeItem">
    <mat-divider></mat-divider>
    <div class="linkRow" routerLink="/report" [class.current]="isRoute('report')" (click)="navigateResetState();"><!--routerLink="/marketingmaterial" [class.current]="isRoute('marketingmaterial')" (click)="navigateResetState();"-->
      <fa-icon [icon]="reportIcon"></fa-icon>
      <span>Reporting</span>
    </div>
    <mat-divider></mat-divider>
    <div class="linkRow" (click)="openCfgMenu()" [class.current]="isRoute('utilities') || isRoute('users')">
        <fa-icon [icon]="cogsIcon"></fa-icon>
        <span>Utilities</span>
        <span [matMenuTriggerFor]="cfgMenu" #cfgMenuTrigger="matMenuTrigger" class="menuAnchor"></span>
    </div>
    <mat-menu #cfgMenu="matMenu" class="navMenu">
      <mat-nav-list class="floatingMenu">
        <a mat-list-item *ngIf="isReferrerCRM() && supportUser(currentUser())" routerLink="/utilities/assessment-templates" [class.current]="isRoute('utilities/assessment-templates')" (click)="navigateResetState();">Assessment Templates</a>
        <a mat-list-item routerLink="/community" [class.current]="isRoute('community')" (click)="navigateResetState();">Community Management</a>
        <a mat-list-item routerLink="/utilities/config" [class.current]="isRoute('utilities/config')" (click)="navigateResetState();">Configurations</a>
        <a mat-list-item routerLink="/utilities/loggedevents" [class.current]="isRoute('utilities/loggedevents')" (click)="navigateResetState();">Logged Events</a>
        <a mat-list-item routerLink="/utilities/lookuptables" [class.current]="isRoute('utilities/lookuptables')" (click)="navigateResetState();">Lookup Tables</a>
        <a mat-list-item routerLink="/utilities/notifications" [class.current]="isRoute('utilities/notifications')" (click)="navigateResetState();">Notifications</a>
        <a mat-list-item routerLink="/users" [class.current]="isRoute('users')" (click)="navigateResetState();">User Management</a>
        <a mat-list-item *ngIf="isRoute('occupancy') && supportUser(currentUser())" (click)="debugUpdateOccupancy();">Update Occupancy Board</a>
      </mat-nav-list>
    </mat-menu>
  </div>
  <div class="relativeItem" *ngIf="usesAmazonConnect()">
    <mat-divider></mat-divider>
    <div class="linkRow" (click)="openPhone()"><!-- [class.current]="isRoute('utilities')" -->
        <fa-icon [icon]="phoneIcon"></fa-icon>
        <span>Phone</span>
    </div>
  </div>
  <div class="relativeItem">
    <mat-divider></mat-divider>
    <div class="user-icon-name-group userRow linkRow" (click)="openUserMenu(); navigateResetState();">
      <person-icon [display]="currentUser()"></person-icon>
      <span class="nameCell">{{currentUser()?.nameDisplay}}</span>
      <span [matMenuTriggerFor]="usrMenu" #usrMenuTrigger="matMenuTrigger" class="menuAnchor"></span>
    </div>
    <mat-menu #usrMenu="matMenu" class="navMenu">
      <mat-nav-list class="floatingMenu">
        <a mat-list-item *ngIf="currentUser()?.superadmin" (click)="openCompanyDialog(); navigateResetState();">Select Company</a>
        @if(isCommunityCRM()){
          <a mat-list-item (click)="openCommunityDialog(); navigateResetState();">Select Communities</a>
        }
        <a mat-list-item (click)="changePasswordDialog();">Change Password</a>
        <a mat-list-item routerLink="/settings" [class.current]="isRoute('settings')" >User Settings</a>
        <a mat-list-item (click)="logout(); navigateResetState();">Log Out</a>
      </mat-nav-list>
    </mat-menu>
  </div>
</div>
<div class="menu-button">
  <button mat-button (click)="open = !open">
    <fa-icon [icon]="menuIcon"></fa-icon>
  </button>
</div>